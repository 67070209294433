import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  ACTION_NAME_FETCH_LICENSE_TOKEN,
  REDUCER_KEY_PLAYER,
} from '../../constants';
import { IPlaylist } from '../../types';
import ApiClient from '../../api/ApiClient';

interface IPlaylistState {
  playlist: IPlaylist | undefined;
  licenseToken: string;
  isFetchingLicenseToken: boolean;
  isPlaying: boolean;
  playingTrackId: string | undefined;
  isShuffled: boolean;
  startedListeningDate: Date | undefined;
  isShowedRateModal: boolean;
}

const initialState: IPlaylistState = {
  playlist: undefined,
  licenseToken: '',
  isFetchingLicenseToken: false,
  isPlaying: false,
  playingTrackId: undefined,
  isShuffled: false,
  startedListeningDate: undefined,
  isShowedRateModal: false,
};

interface IFetchLicenseToken {
  drm_type: string | 'Widevine';
  track_id: string;
}

const fetchLicenseToken = createAsyncThunk<AxiosResponse<any>, IFetchLicenseToken>(
  `${REDUCER_KEY_PLAYER}/${ACTION_NAME_FETCH_LICENSE_TOKEN}`,
  async ({
    drm_type,
    track_id,
    // eslint-disable-next-line arrow-body-style
  }) => {
    const response = await ApiClient.fetchLicenseToken({ drm_type, track_id });
    return response;
  },
);

const playlistSlice = createSlice<IPlaylistState,
  SliceCaseReducers<IPlaylistState>,
  typeof REDUCER_KEY_PLAYER>({
    name: REDUCER_KEY_PLAYER,
    initialState,
    reducers: {
      setPlaylist: (state, action: PayloadAction<IPlaylist>) => {
        state.playlist = action.payload;
      },
      setPlay: (state, action: PayloadAction<{ isPlaying: boolean }>) => {
        state.isPlaying = action.payload.isPlaying;
      },
      setPlayingTrack: (state, action: PayloadAction<{ trackId: string }>) => {
        state.playingTrackId = action.payload.trackId;
      },
      setShufflePlay: (state, action: PayloadAction<{ isShuffled: boolean }>) => {
        state.isShuffled = action.payload.isShuffled;
      },
      setStartedListeningDate: (state, action: PayloadAction<{
        startedListeningDate: Date | undefined,
      }>) => {
        state.startedListeningDate = action.payload.startedListeningDate;
      },
      setIsShowedRateModal: (state, action: PayloadAction<{ isShowedRateModal: boolean }>) => {
        state.isShowedRateModal = action.payload.isShowedRateModal;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchLicenseToken.pending, (state) => {
          state.isFetchingLicenseToken = true;
        })
        .addCase(fetchLicenseToken.fulfilled, (state, { payload }) => {
          state.licenseToken = payload.data.license_token;
          state.isFetchingLicenseToken = false;
        })
        .addCase(fetchLicenseToken.rejected, (state, { payload }) => {
          state.licenseToken = '';
          state.isFetchingLicenseToken = false;
        });
    },
  });

const {
  setPlaylist,
  setPlay,
  setPlayingTrack,
  setShufflePlay,
  setStartedListeningDate,
  setIsShowedRateModal,
} = playlistSlice.actions;

export {
  setPlaylist,
  setPlay,
  setPlayingTrack,
  setShufflePlay,
  fetchLicenseToken,
  setStartedListeningDate,
  setIsShowedRateModal,
};

export default playlistSlice.reducer;

import {
  createAsyncThunk,
  createSlice,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  IAdminAccount,
  IFacility,
} from '../../types';
import {
  ACTION_NAME_DELETE_ADMIN_ACCOUNT,
  ACTION_NAME_FETCH_ADMIN_ACCOUNT,
  ACTION_NAME_CREATE_ADMIN_ACCOUNT,
  REDUCER_KEY_SETTINGS,
  ACTION_NAME_FETCH_ACCOUNT_FACILITY,
  ACTION_NAME_SEARCH_FACILITIES,
  ACTION_NAME_UPDATE_ADMIN_ACCOUNT,
  ACTION_NAME_CREATE_ACCOUNT_FACILITY,
  ACTION_NAME_DELETE_ACCOUNT_FACILITY,
  ACTION_NAME_INVITE_ACCOUNT_FACILITY,
} from '../../constants';
import ApiClient from '../../api/ApiClient';

interface ISettingsState {
  isFetchingAdminAccount: boolean;
  adminAccount: IAdminAccount | undefined;
  message: string | null;
  isFetchingFacility: boolean;
  facility: IFacility | undefined;
  isSearching: boolean;
  searchResults: Array<IFacility>;
}
const initialState: ISettingsState = {
  isFetchingAdminAccount: false,
  adminAccount: undefined,
  message: null,
  isFetchingFacility: false,
  facility: undefined,
  isSearching: false,
  searchResults: [],
};

const fetchAdminAccount = createAsyncThunk<AxiosResponse<IAdminAccount>>(
  `${REDUCER_KEY_SETTINGS}/${ACTION_NAME_FETCH_ADMIN_ACCOUNT}`,
  async (arg) => {
    const response = await ApiClient.fetchAdminAccount();
    return response;
  },
);

const deleteAdminAccount = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_SETTINGS}/${ACTION_NAME_DELETE_ADMIN_ACCOUNT}`,
  async (arg) => {
    const response = await ApiClient.removeAdminAccount();
    return response;
  },
);

const createAdminAccount = createAsyncThunk<AxiosResponse<any>, IAdminAccount>(
  `${REDUCER_KEY_SETTINGS}/${ACTION_NAME_CREATE_ADMIN_ACCOUNT}`,
  async ({
    email,
    name,
  }, { dispatch }) => {
    const response = await ApiClient.createAdminAccount(email, name);
    if (response.status === 200) {
      dispatch(fetchAdminAccount());
    }
    return response;
  },
);

const updateAdminAccount = createAsyncThunk<AxiosResponse<any>, IAdminAccount>(
  `${REDUCER_KEY_SETTINGS}/${ACTION_NAME_UPDATE_ADMIN_ACCOUNT}`,
  async ({
    email,
    name,
  }, { dispatch }) => {
    const response = await ApiClient.updateAdminAccount(email, name);
    dispatch(fetchAdminAccount());
    return response;
  },
);

const fetchAccountFacility = createAsyncThunk<AxiosResponse<IFacility>>(
  `${REDUCER_KEY_SETTINGS}/${ACTION_NAME_FETCH_ACCOUNT_FACILITY}`,
  async (arg) => {
    const response = await ApiClient.fetchAccountFacility();
    return response;
  },
);

const searchFacilities = createAsyncThunk<AxiosResponse<Array<IFacility>>, {
  keyword: string,
}>(
  `${REDUCER_KEY_SETTINGS}/${ACTION_NAME_SEARCH_FACILITIES}`,
  async ({
    keyword,
  }) => {
    const response = await ApiClient.searchFacilities(keyword);
    return response;
  },
);

const createAccountFacility = createAsyncThunk<AxiosResponse<any>, {
  facility: string,
}>(
  `${REDUCER_KEY_SETTINGS}/${ACTION_NAME_CREATE_ACCOUNT_FACILITY}`,
  async (
    { facility },
    { dispatch },
  ) => {
    const response = await ApiClient.createAccountFacility(facility);
    if (response.data.facility) {
      dispatch(fetchAccountFacility());
    }
    return response;
  },
);

const deleteAccountFacility = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_SETTINGS}/${ACTION_NAME_DELETE_ACCOUNT_FACILITY}`,
  async (arg) => {
    const response = await ApiClient.removeAccountFacility();
    return response;
  },
);

const inviteAccountFacility = createAsyncThunk<AxiosResponse<any>, { email: string }>(
  `${REDUCER_KEY_SETTINGS}/${ACTION_NAME_INVITE_ACCOUNT_FACILITY}`,
  async ({ email }) => {
    const response = await ApiClient.inviteAccountFacility(email);
    return response;
  },
);

const settingsSlice = createSlice<ISettingsState, SliceCaseReducers<ISettingsState>,
  typeof REDUCER_KEY_SETTINGS>({
    name: REDUCER_KEY_SETTINGS,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchAdminAccount.pending, (state) => {
          state.isFetchingAdminAccount = true;
          state.message = null;
        })
        .addCase(fetchAdminAccount.fulfilled, (state, { payload }) => {
          state.isFetchingAdminAccount = false;
          state.message = null;
          state.adminAccount = payload.data;
        })
        .addCase(deleteAdminAccount.pending, (state) => {
          state.isFetchingAdminAccount = true;
          state.message = null;
        })
        .addCase(deleteAdminAccount.fulfilled, (state, { payload }) => {
          state.isFetchingAdminAccount = false;
          state.message = null;
          state.adminAccount = undefined;
        })
        .addCase(createAdminAccount.pending, (state) => {
          state.isFetchingAdminAccount = true;
          state.message = null;
        })
        .addCase(createAdminAccount.fulfilled, (state, { payload }) => {
          state.isFetchingAdminAccount = false;
          state.message = payload.data.message;
        })
        .addCase(updateAdminAccount.pending, (state) => {
          state.isFetchingAdminAccount = true;
          state.message = null;
        })
        .addCase(updateAdminAccount.fulfilled, (state, { payload }) => {
          state.isFetchingAdminAccount = false;
          state.message = payload.data.message;
        })
        .addCase(fetchAccountFacility.pending, (state) => {
          state.isFetchingFacility = true;
        })
        .addCase(fetchAccountFacility.fulfilled, (state, { payload }) => {
          state.isFetchingFacility = false;
          state.facility = payload.data;
        })
        .addCase(createAccountFacility.pending, (state) => {
          state.isFetchingFacility = true;
        })
        .addCase(createAccountFacility.fulfilled, (state, { payload }) => {
          state.isFetchingFacility = false;
        })
        .addCase(deleteAccountFacility.pending, (state) => {
          state.isFetchingFacility = true;
        })
        .addCase(deleteAccountFacility.fulfilled, (state, { payload }) => {
          state.isFetchingFacility = false;
          state.facility = undefined;
        })
        .addCase(searchFacilities.pending, (state) => {
          state.isSearching = true;
        })
        .addCase(searchFacilities.fulfilled, (state, { payload }) => {
          state.isSearching = false;
          state.searchResults = payload.data;
        });
    },
  });

export {
  fetchAdminAccount,
  deleteAdminAccount,
  createAdminAccount,
  updateAdminAccount,
  fetchAccountFacility,
  createAccountFacility,
  deleteAccountFacility,
  inviteAccountFacility,
  searchFacilities,
};

export default settingsSlice.reducer;

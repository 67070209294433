import {
  createAsyncThunk,
  createSlice,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IActivity } from '../../types';
import {
  ACTION_NAME_FETCH_LATEST_ACTIVITY,
  REDUCER_KEY_ACTIVITY,
} from '../../constants';
import ApiClient from '../../api/ApiClient';

interface IActivityState {
  isFetching: boolean;
  offset: number;
  limit: number;
  latestActivity: Array<IActivity>;
  count: number;
  next: string | undefined;
  previous: string | undefined;
}

const initialState: IActivityState = {
  isFetching: false,
  offset: 0,
  limit: 0,
  latestActivity: [],
  count: 0,
  next: undefined,
  previous: undefined,
};

interface IActivityListParams {
  profile_id: string;
  offset?: number;
  limit?: number;
}

const fetchLatestActivity = createAsyncThunk<AxiosResponse<any>, IActivityListParams>(
  `${REDUCER_KEY_ACTIVITY}/${ACTION_NAME_FETCH_LATEST_ACTIVITY}`,
  async ({ profile_id, offset = 0, limit = 15 }) => {
    const response = await ApiClient.fetchLatestActivity({ profile_id, offset, limit });
    return response;
  },
);

const activitySlice = createSlice<IActivityState, SliceCaseReducers<IActivityState>,
  typeof REDUCER_KEY_ACTIVITY>({
    name: REDUCER_KEY_ACTIVITY,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchLatestActivity.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(fetchLatestActivity.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(fetchLatestActivity.fulfilled, (state, { payload }) => {
          state.isFetching = false;
          state.latestActivity = payload.data.results;
          state.count = payload.data.count;
          state.next = payload.data.next;
          state.previous = payload.data.previous;
        });
    },
  });

export {
  fetchLatestActivity,
};

export default activitySlice.reducer;

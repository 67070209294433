import { AxiosResponse } from 'axios';
import {
  createAsyncThunk,
  createSlice,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

import {
  ACTION_NAME_ADD_TEAM_MEMBER,
  ACTION_NAME_FETCH_FACILITY_DATA,
  ACTION_NAME_FETCH_MEMBER_EMAIL,
  ACTION_NAME_FETCH_TEAM_MEMBERS,
  ACTION_NAME_PATCH_FACILITY_DATA,
  ACTION_NAME_PATCH_MEMBER_DATA,
  ACTION_NAME_REMOVE_TEAM_MEMBER,
  ACTION_NAME_SEND_REMINDER_TO_TEAM_MEMBER,
  ACTION_NAME_SET_MEMBER_PASSWORD,
  REDUCER_KEY_FACILITY,
} from '../../constants';
import ApiClient from '../../api/ApiClient';
import { IFacility, ITeamMember } from '../../types';

interface IFacilityState {
  isFetching: boolean;
  data: IFacility | undefined;
  teamMembers: Array<ITeamMember>;
  member_data: {
    [key: string]: string;
  };
}

const initialState: IFacilityState = {
  isFetching: false,
  data: undefined,
  teamMembers: [],
  member_data: {},
};

const patchFacilityData = createAsyncThunk<AxiosResponse<any>, {
  facility_id: string,
  name?: string,
  location?: string,
  postcode?: string,
  website?: string,
  residents?: string,
  health_care_type?: string,
  city?: string,
}>(
  `${REDUCER_KEY_FACILITY}/${ACTION_NAME_PATCH_FACILITY_DATA}`,
  async ({
    facility_id,
    name,
    location,
    postcode,
    website,
    residents,
    health_care_type,
    city,
  }) => {
    const response = await ApiClient.patchFacilityData(
      facility_id,
      name,
      location,
      postcode,
      website,
      residents,
      health_care_type,
      city,
    );
    return response;
  },
);

const fetchFacilityData = createAsyncThunk<AxiosResponse<any>, { facility_id: string }>(
  `${REDUCER_KEY_FACILITY}/${ACTION_NAME_FETCH_FACILITY_DATA}`,
  async ({ facility_id }) => {
    const response = await ApiClient.fetchFacilityData(facility_id);
    return response;
  },
);

const fetchTeamMembers = createAsyncThunk<AxiosResponse<any>, { facility_id: string }>(
  `${REDUCER_KEY_FACILITY}/${ACTION_NAME_FETCH_TEAM_MEMBERS}`,
  async ({ facility_id }) => {
    const response = await ApiClient.fetchTeamMembers(facility_id);
    return response;
  },
);

const addTeamMember = createAsyncThunk<AxiosResponse<any>, {
  facility_id: string,
  name: string,
  email: string,
}>(
  `${REDUCER_KEY_FACILITY}/${ACTION_NAME_ADD_TEAM_MEMBER}`,
  async ({
    facility_id,
    name,
    email,
    // eslint-disable-next-line arrow-body-style
  }) => {
    const response = await ApiClient.addTeamMember(facility_id, name, email);
    if (response.status >= 400) {
      return Promise.reject(new Error(response.data));
    }
    return response;
  },
);

const removeTeamMember = createAsyncThunk<AxiosResponse<any>, {
  facility_id: string,
  member_id: string,
}>(
  `${REDUCER_KEY_FACILITY}/${ACTION_NAME_REMOVE_TEAM_MEMBER}`,
  async ({
    facility_id,
    member_id,
    // eslint-disable-next-line arrow-body-style
  }) => {
    const response = await ApiClient.removeTeamMember(facility_id, member_id);
    return response;
  },
);

const sendReminderToTeamMember = createAsyncThunk<AxiosResponse<any>, {
  facility_id: string,
  member_id: string,
}>(
  `${REDUCER_KEY_FACILITY}/${ACTION_NAME_SEND_REMINDER_TO_TEAM_MEMBER}`,
  async ({
    facility_id,
    member_id,
    // eslint-disable-next-line arrow-body-style
  }) => {
    const response = await ApiClient.sendReminderToTeamMember(facility_id, member_id);
    return response;
  },
);

const fetchMemberEmail = createAsyncThunk<AxiosResponse<any>, {
  facility_id: string,
  member_id: string,
  token: string,
}>(
  `${REDUCER_KEY_FACILITY}/${ACTION_NAME_FETCH_MEMBER_EMAIL}`,
  async ({
    facility_id,
    member_id,
    token,
  }) => {
    const response = await ApiClient.fetchMemberEmail(facility_id, member_id, token);
    return response;
  },
);

const patchMemberData = createAsyncThunk<
  AxiosResponse<any>,
  {
    facility_id: string,
    member_id: string,
    email?: string,
    id?: string,
    name?: string,
    location?: string,
    health_care_type?: string,
  }
>(
  `${REDUCER_KEY_FACILITY}/${ACTION_NAME_PATCH_MEMBER_DATA}`,
  async (
    {
      facility_id,
      member_id,
      email,
      id,
      name,
      location,
      health_care_type,
    },
  ) => {
    const response = await ApiClient.patchMemberData(
      facility_id,
      member_id,
      email,
      id,
      name,
      location,
      health_care_type,
    );
    return response;
  },
);

const facilitySlice = createSlice<IFacilityState, SliceCaseReducers<IFacilityState>,
  typeof REDUCER_KEY_FACILITY>({
    name: REDUCER_KEY_FACILITY,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchFacilityData.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(fetchFacilityData.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(fetchFacilityData.fulfilled, (state, { payload }) => {
          state.data = payload.data;
          state.isFetching = false;
        })
        .addCase(patchFacilityData.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(patchFacilityData.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(patchFacilityData.fulfilled, (state, { payload }) => {
          state.data = payload.data;
          state.isFetching = false;
        })
        .addCase(fetchTeamMembers.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(fetchTeamMembers.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(fetchTeamMembers.fulfilled, (state, { payload }) => {
          state.isFetching = false;
          state.teamMembers = payload.data;
        })
        .addCase(addTeamMember.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(addTeamMember.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(addTeamMember.fulfilled, (state, { payload }) => {
          state.isFetching = false;
          state.teamMembers = [...state.teamMembers, payload.data];
        })
        .addCase(removeTeamMember.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(removeTeamMember.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(removeTeamMember.fulfilled, (state, { payload }) => {
          state.isFetching = false;
          // state.teamMembers = payload.data;
        })
        .addCase(sendReminderToTeamMember.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(sendReminderToTeamMember.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(sendReminderToTeamMember.fulfilled, (state, { payload }) => {
          state.isFetching = false;
          // state.teamMembers = payload.data;
        })
        .addCase(fetchMemberEmail.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(fetchMemberEmail.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(fetchMemberEmail.fulfilled, (state, { payload }) => {
          state.isFetching = false;
          state.member_data = payload.data;
        })
        .addCase(patchMemberData.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(patchMemberData.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(patchMemberData.fulfilled, (state, { payload }) => {
          state.isFetching = false;
          state.member_data = payload.data;
        });
    },
  });

export {
  fetchFacilityData,
  fetchTeamMembers,
  addTeamMember,
  removeTeamMember,
  sendReminderToTeamMember,
  patchFacilityData,
  fetchMemberEmail,
  patchMemberData,
};

export default facilitySlice.reducer;

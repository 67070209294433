import React from 'react';
import classNames from 'classnames';
import veraLogo from '../../source/vera_logo.svg';
import veraLogoPro from '../../source/logo-pro.svg';
import { useAppSelector } from '../../store/hooks';
import { REDUCER_KEY_AUTH, VERA_TYPE } from '../../constants';

const Logo: React.FunctionComponent = () => {
  const {
    veraType,
  } = useAppSelector((state) => ({
    veraType: state[REDUCER_KEY_AUTH].veraType,
  }));

  return (
    <img
      className={classNames('vera-logo', {
        'vera-logo--pro': veraType === VERA_TYPE.PRO,
      })}
      alt=""
      src={veraType === VERA_TYPE.PRO ? veraLogoPro : veraLogo}
    />
  );
};

export default Logo;

import ROUTES from './routes/constants';
import { TMenuButton, TMenuLink } from './types';
import { ICON_NAMES } from './components/Icons';

//
export const SECONDS_IN_HOUR = 3600;
export const SECONDS_IN_MINUTE = 60;

// Redux
export const REDUCER_KEY_AUTH = 'auth';
export const ACTION_NAME_LOGIN_USER = 'loginUser';
export const ACTION_NAME_LOGOUT_USER = 'logoutUser';
export const ACTION_NAME_SIGNUP_USER = 'signupUser';
export const ACTION_NAME_SIGNUP_USER_PRO = 'signupUserPro';
export const ACTION_NAME_LOGIN_USER_PRO = 'loginUserPro';
export const ACTION_NAME_FORGOT_PASSWORD_USER = 'forgotPasswordUser';
export const ACTION_NAME_RESET_PASSWORD_USER = 'resetPasswordUser';
export const ACTION_NAME_REFRESH_USER_TOKEN = 'refreshUserToken';
export const ACTION_NAME_NOTIFY_USER = 'notifyUser';
export const ACTION_NAME_VERIFY_EMAIL_REQUIRING = 'verifyEmailRequiring';
export const ACTION_NAME_VERIFY_EMAIL = 'verifyEmail';
export const ACTION_NAME_GOOGLE_LOGIN_USER = 'googleLoginUser';
export const ACTION_NAME_FACEBOOK_LOGIN_USER = 'facebookLoginUser';
export const ACTION_NAME_APPLE_LOGIN_USER = 'appleLoginUser';
export const ACTION_NAME_APPEND_TOKENS = 'appendTokens';
export const ACTION_NAME_GUEST_ADMIN_SET_PASSWORD = 'setPassword';
export const ACTION_NAME_GET_COUNTRY_BY_IP = 'getCountryByIP';

export const REDUCER_KEY_PROFILE = 'profile';
export const ACTION_NAME_FETCH_PROFILE_DATA = 'fetchProfileData';
export const ACTION_NAME_FETCH_ACCOUNT_ADMIN_INFO = 'fetchAccountAdminInfo';
export const ACTION_NAME_PUT_ACCOUNT_ADMIN_LOCATION = 'putAccountAdminLocation';
export const ACTION_NAME_FETCH_PROFILES = 'fetchProfiles';
export const ACTION_NAME_FETCH_PLAYLISTS = 'fetchPlaylists';
export const ACTION_NAME_REFRESH_PLAYLISTS = 'refreshPlaylists';
export const ACTION_NAME_GENERATE_PLAYLISTS = 'generatePlaylists';
export const ACTION_NAME_FETCH_PRO_USER_PROFILE_DATA = 'fetchProUserProfileData';
export const ACTION_NAME_PATCH_PRO_USER_PROFILE_DATA = 'patchProUserProfileData';

export const ACTION_NAME_CREATE_PROFILE = 'createProfile';
export const ACTION_NAME_UPDATE_PROFILE = 'updateProfile';
export const ACTION_NAME_UPDATE_PROFILE_DATA = 'updateProfileData';
export const ACTION_NAME_PATCH_PROFILE_DATA = 'patchProfileData';
export const ACTION_NAME_UPDATE_PASSWORD = 'updatePassword';
export const ACTION_NAME_FETCH_PROFILE_PHOTO = 'fetchProfilePhoto';
export const ACTION_NAME_UPDATE_PROFILE_PHOTO = 'updateProfilePhoto';
export const ACTION_NAME_PATCH_PROFILE_PHOTO = 'patchProfilePhoto';
export const ACTION_NAME_PATCH_ADD_EXISTING_RESIDENT = 'addExistingResident';
export const ACTION_NAME_PATCH_ADD_NEW_RESIDENT_PROFILE = 'addNewResidentProfile';
export const ACTION_NAME_PATCH_REMIND_INVITED_RESIDENT = 'remindInvitedResident';
export const ACTION_NAME_PATCH_REMOVE_INVITED_RESIDENT = 'removeInvitedResident';

export const ACTION_NAME_CREATE_PROFILE_PRO = 'createProfilePro';
export const ACTION_NAME_PATCH_PROFILE_PRO = 'patchProfilePro';
export const ACTION_NAME_GET_ACCOUNT_PRO_DATA = 'getUserProfilePro';
export const ACTION_NAME_GET_FACILITY_FROFILE_DATA = 'getFacilityProfileData';

export const REDUCER_KEY_COMMON = 'common';
export const ACTION_RESIZE_WINDOW = 'resizeWindow';

export const REDUCER_KEY_ARTISTS = 'artists';
export const ACTION_NAME_SEARCH_ARTISTS = 'searchArtists';
export const ACTION_NAME_ADD_ARTISTS = 'addArtists';
export const ACTION_NAME_FETCH_FAVOURITE_ARTISTS = 'fetchFavouriteArtists';
export const ACTION_NAME_UPDATE_FAVOURITE_ARTISTS = 'updateFavouriteArtists';
export const ACTION_NAME_FETCH_SUGGESTED_ARTISTS = 'fetchSuggestedArtists';

export const REDUCER_KEY_GENRES = 'genres';
export const ACTION_NAME_FETCH_GENRES = 'fetchGenres';
export const ACTION_NAME_FETCH_FAVOURITE_GENRES = 'fetchFavouriteGenres';
export const ACTION_NAME_UPDATE_FAVOURITE_GENRES = 'updateFavouriteGenres';

export const REDUCER_KEY_PLAYER = 'player';
export const ACTION_NAME_SET_PLAYLIST = 'setPlaylist';
export const ACTION_NAME_FETCH_LICENSE_TOKEN = 'fetchLicenseToken';

export const REDUCER_KEY_TRACKS = 'tracks';
export const ACTION_NAME_FETCH_TRACKS_PREFERENCES = 'fetchTracksPreferences';
export const ACTION_NAME_UPDATE_TRACKS_PREFERENCES = 'updateTracksPreferences';
export const ACTION_NAME_RATE_MUSIC = 'rateMusic';

export const REDUCER_KEY_LANGUAGES = 'languages';
export const ACTION_NAME_FETCH_LANGUAGES = 'fetchLanguages';

export const REDUCER_KEY_COUNTRIES = 'countries';
export const ACTION_NAME_FETCH_COUNTRIES = 'fetchCountries';

export const REDUCER_KEY_STRIPE = 'stripe';
export const ACTION_NAME_CHECKOUT = 'checkout';
export const ACTION_NAME_FETCH_PORTAL_LINK = 'portal';
export const ACTION_NAME_FETCH_MEMBERSHIP_DETAILS = 'membershipDetails';

export const REDUCER_KEY_MODAL = 'modal';
export const REDUCER_KEY_CHAT = 'chat';

export const REDUCER_KEY_SETTINGS = 'settings';
export const ACTION_NAME_FETCH_ADMIN_ACCOUNT = 'fetchAdminAccount';
export const ACTION_NAME_DELETE_ADMIN_ACCOUNT = 'deleteAdminAccount';
export const ACTION_NAME_CREATE_ADMIN_ACCOUNT = 'createAdminAccount';
export const ACTION_NAME_UPDATE_ADMIN_ACCOUNT = 'updateAdminAccount';
export const ACTION_NAME_FETCH_ACCOUNT_FACILITY = 'fetchAccountFacility';
export const ACTION_NAME_CREATE_ACCOUNT_FACILITY = 'createAccountFacility';
export const ACTION_NAME_DELETE_ACCOUNT_FACILITY = 'deleteAccountFacility';
export const ACTION_NAME_INVITE_ACCOUNT_FACILITY = 'inviteAccountFacility';
export const ACTION_NAME_SEARCH_FACILITIES = 'createSearchFacilities';

export const REDUCER_KEY_ACTIVITY = 'activity';
export const ACTION_NAME_FETCH_LATEST_ACTIVITY = 'fetchLatestActivity';

export const REDUCER_KEY_FACILITY = 'facility';
export const ACTION_NAME_FETCH_FACILITY_DATA = 'fetchFacilityData';
export const ACTION_NAME_PATCH_FACILITY_DATA = 'patchFacilityData';
export const ACTION_NAME_FETCH_TEAM_MEMBERS = 'fetchTeamMembers';
export const ACTION_NAME_ADD_TEAM_MEMBER = 'addTeamMember';
export const ACTION_NAME_REMOVE_TEAM_MEMBER = 'removeTeamMember';
export const ACTION_NAME_SEND_REMINDER_TO_TEAM_MEMBER = 'sendReminderToTeamMember';
export const ACTION_NAME_FETCH_MEMBER_EMAIL = 'fetchMemberEmail';
export const ACTION_NAME_SET_MEMBER_PASSWORD = 'setMemberPassword';
export const ACTION_NAME_PATCH_MEMBER_DATA = 'patchMemberData';

// Fields
export const DATE = 'date';
export const MONTH = 'Month';
export const YEAR = 'Year';
export const DATE_OF_BIRTH = 'Date of Birth';
export const YEAR_OF_BIRTH = 'Year of Birth';
// export const GENDER = 'Gender';
// export const NAME = 'Name';
// export const EMAIL_ID= 'Email ID';
export const PROFILE_PHOTO = 'Profile Photo (Optional)';

// Days options
export const DAYS = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
  { label: '13', value: 13 },
  { label: '14', value: 14 },
  { label: '15', value: 15 },
  { label: '16', value: 16 },
  { label: '17', value: 17 },
  { label: '18', value: 18 },
  { label: '19', value: 19 },
  { label: '20', value: 20 },
  { label: '21', value: 21 },
  { label: '22', value: 22 },
  { label: '23', value: 23 },
  { label: '24', value: 24 },
  { label: '25', value: 25 },
  { label: '26', value: 26 },
  { label: '27', value: 27 },
  { label: '28', value: 28 },
  { label: '29', value: 29 },
  { label: '30', value: 30 },
  { label: '31', value: 31 },
];

export const musicCards = [
  {
    path: ROUTES.RELAX,
    img: '/images/relaxIllustration.png',
    title: 'Relax',
    description: 'Feeling a bit stressed or agitated? Relax with the right music for you.',
    className: 'music-type--relax',
  },
  {
    path: ROUTES.REMINISCE,
    img: '/images/reminisceIllustration.png',
    title: 'Reminisce',
    description: 'Reminisce over happy moments from your past, in the present.',
    className: 'music-type--reminisce',
  },
  {
    path: ROUTES.ENERGISE,
    img: '/images/energiseIllustration.png',
    title: 'Energise',
    description: 'Need a boost of dopamine? These songs will give you the motivation boost you\'re looking for.',
    className: 'music-type--energise',
  },
];

export enum GENDERS {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other',
  UNSPECIFIED = 'Unspecified',
}

export const MENU_LINKS: Array<TMenuLink | TMenuButton> = [
  {
    name: 'Home',
    path: ROUTES.HOME,
    iconName: ICON_NAMES.HOME,
    type: 'link',
  },
  {
    name: 'Playlist',
    iconName: ICON_NAMES.PLAY_LIST,
    type: 'parent',
    children: [
      {
        name: 'Relax',
        path: ROUTES.RELAX,
      },
      {
        name: 'Reminisce',
        path: ROUTES.REMINISCE,
      },
      {
        name: 'Energise',
        path: ROUTES.ENERGISE,
      },
    ],
  },
  {
    name: 'Profile',
    iconName: ICON_NAMES.PROFILE,
    type: 'parent',
    children: [
      {
        name: 'Personal Info',
        path: ROUTES.PERSONAL_INFO,
      },
      {
        name: 'Music Preferences',
        path: ROUTES.MUSIC_PREFERENCES,
      },
    ],
  },
  {
    name: 'Account',
    iconName: ICON_NAMES.ACCOUNT,
    type: 'parent',
    children: [
      {
        name: 'Membership',
        path: ROUTES.MEMBERSHIP,
      },
      {
        name: 'Settings',
        path: ROUTES.SETTINGS,
      },
    ],
  },
];

export const MOBILE_MENU_LINKS: Array<TMenuLink | TMenuButton> = [
  {
    name: 'Home',
    path: ROUTES.HOME,
    iconName: ICON_NAMES.HOME,
    type: 'link',
  },
  {
    name: 'Playlist',
    path: ROUTES.PLAYLIST,
    iconName: ICON_NAMES.PLAY_LIST,
    type: 'link',
  },
  {
    name: 'Profile',
    path: ROUTES.PROFILE,
    iconName: ICON_NAMES.PROFILE,
    type: 'link',
  },
  {
    name: 'Account',
    path: ROUTES.SETTINGS,
    iconName: ICON_NAMES.SETTINGS,
    type: 'link',
  },
];

export const PLAYLIST_MENU = [
  {
    name: 'Relax',
    path: ROUTES.RELAX,
    description: 'Feeling a bit stressed or agitated?',
    color: '#0BCD9F',
    type: 'link',
  },
  {
    name: 'Reminisce',
    path: ROUTES.REMINISCE,
    description: 'Helps you relive happy moments from your past.',
    color: '#F06669',
    type: 'link',
  },
  {
    name: 'Energise',
    path: ROUTES.ENERGISE,
    description: 'Need a boost of dopamine? Songs will give you the boost.',
    color: '#FBB01B',
    type: 'link',
  },
];

export const PROFILE_MENU = [
  {
    name: 'Personal Info',
    path: ROUTES.PERSONAL_INFO,
    description: 'Edit your personal details',
    type: 'link',
  },
  {
    name: 'Music Preferences',
    path: ROUTES.MUSIC_PREFERENCES,
    description: 'Manage songs, genre, artist',
    type: 'link',
  },
];
export const ACCOUNT_MENU = [
  {
    name: 'Membership',
    path: ROUTES.MEMBERSHIP,
    description: 'Manage your membership details',
    type: 'link',
  },
  {
    name: 'Password',
    path: ROUTES.SETTINGS_PASSWORD,
    description: 'Manage your Vera password',
    type: 'link',
  },
  {
    name: 'Guest Admin',
    path: ROUTES.SETTINGS_GUEST_ADMIN,
    description: 'Manage guest admins',
    type: 'link',
  },
  {
    name: 'Vera Pro',
    path: ROUTES.SETTINGS_SHARE_PROFILE,
    description: 'Manage your Vera Pro',
    type: 'link',
  },
];

export const ACCOUNT_MENU_PRO = [
  {
    name: 'Membership',
    path: ROUTES.MEMBERSHIP,
    description: 'Manage your membership details',
    type: 'link',
  },
  {
    name: 'Personal Information',
    path: ROUTES.PERSONAL_INFO,
    description: 'Manage your personal information',
    type: 'link',
  },
  {
    name: 'Password',
    path: ROUTES.SETTINGS_PASSWORD,
    description: 'Manage your Vera password',
    type: 'link',
  },
  {
    name: 'Team Members',
    path: ROUTES.SETTINGS_TEAM_MEMBERS,
    description: 'Manage your team members',
    type: 'link',
  },
];

export const LISTENER_MODE_MENU_LINKS: Array<TMenuLink | TMenuButton> = [
  {
    name: 'Home',
    path: ROUTES.HOME,
    iconName: ICON_NAMES.HOME,
    type: 'link',
  },
  {
    name: 'Switch',
    type: 'button',
  },
  {
    name: 'Music Stations',
    iconName: ICON_NAMES.PLAY_LIST,
    type: 'parent',
    children: [
      {
        name: 'Relax',
        path: ROUTES.RELAX,
      },
      {
        name: 'Reminisce',
        path: ROUTES.REMINISCE,
      },
      {
        name: 'Energise',
        path: ROUTES.ENERGISE,
      },
    ],
  },
  {
    name: 'Profile',
    iconName: ICON_NAMES.PROFILE,
    type: 'parent',
    children: [
      {
        name: 'Personal Info',
        path: ROUTES.PERSONAL_INFO,
      },
      {
        name: 'Music Preferences',
        path: ROUTES.MUSIC_PREFERENCES,
      },
    ],
  },
];

export const CARER_MODE_MENU_LINKS: Array<TMenuLink | TMenuButton> = [
  {
    name: 'Home',
    path: ROUTES.HOME,
    iconName: ICON_NAMES.HOME,
    type: 'link',
  },
  {
    name: 'Switch',
    type: 'button',
  },
  {
    name: 'Manage',
    iconName: ICON_NAMES.PROFILE,
    type: 'parent',
    children: [
      {
        name: 'Profiles',
        path: ROUTES.PROFILES,
      },
      {
        name: 'Scheduling (coming soon)',
        path: '',
        disabled: true,
      },
    ],
  },
  {
    name: 'Account',
    iconName: ICON_NAMES.ACCOUNT,
    type: 'parent',
    children: [
      {
        name: 'Membership',
        path: ROUTES.MEMBERSHIP,
      },
      {
        name: 'Settings',
        path: ROUTES.SETTINGS,
      },
    ],
  },
];

export const CARER_MODE_MOBILE_MENU_LINKS: Array<TMenuLink | TMenuButton> = [
  {
    name: 'Home',
    path: ROUTES.HOME,
    iconName: ICON_NAMES.HOME,
    type: 'link',
  },
  {
    name: 'Switch',
    iconName: ICON_NAMES.SWITCH,
    type: 'button',
  },
  {
    name: 'Manage',
    path: ROUTES.MANAGE,
    iconName: ICON_NAMES.PROFILE,
    type: 'link',
  },
  {
    name: 'Account',
    path: ROUTES.SETTINGS,
    iconName: ICON_NAMES.SETTINGS,
    type: 'link',
  },
];

export const LISTENER_MODE_MOBILE_MENU_LINKS: Array<TMenuLink | TMenuButton> = [
  {
    name: 'Home',
    path: ROUTES.HOME,
    iconName: ICON_NAMES.HOME,
    type: 'link',
  },
  {
    name: 'Switch',
    iconName: ICON_NAMES.SWITCH,
    type: 'button',
  },
  {
    name: 'Music',
    path: ROUTES.PLAYLIST,
    iconName: ICON_NAMES.PLAY_LIST,
    type: 'link',
  },
  {
    name: 'Profile',
    path: ROUTES.PROFILE,
    iconName: ICON_NAMES.PROFILE,
    type: 'link',
  },
];

export const MANAGE_MENU = [
  {
    name: 'Profiles',
    path: ROUTES.PROFILES,
    description: 'Manage resident’s profiles',
    type: 'link',
  },
  {
    name: 'Scheduling',
    path: ROUTES.SCHEDULING,
    description: 'Schedule appointments',
    type: 'link',
  },
];

export const PLAYLIST_TYPE_RELAX = 'RELAX';
export const PLAYLIST_TYPE_REMINISCE = 'REMINISCE';
export const PLAYLIST_TYPE_ENERGISE = 'ENERGISE';

export enum EMODALS {
  LIKE_SONG = 'like-song',
  REMOVE_SONG = 'remove-song',
  ADD_BACK_SONG = 'add-back-song',
  REMOVE_DISLIKE = 'remove-dislike',
  CHANGE_PLAN = 'change-plan',
  CANCEL_MEMBERSHIP = 'cancel-membership',
  CHANGE_PAYMENT_METHOD = 'change-payment-method',
  LOGOUT = 'logout',
  RENEW_MEMBERSHIP = 'renew-membership',
  START_TRIAL = 'start-trial',
  VERIFY_EMAIL = 'verify-email',
  DEMO_COMPLETE = 'demo-complete',
  KNOW_MORE_ABOUT_VERA = 'know-more-about-vera',
  VIDEO_CONTENT = 'video-content',
  TAKE_PHOTO = 'take-photo',
  GUEST_ADMIN_CHANGE = 'guest-admin-change',
  GUEST_ADMIN_DELETE = 'guest-admin-delete',
  ACCOUNT_FACILITY_DELETE = 'account-facility-delete',
  ACCOUNT_FACILITY_INVITE = 'account-facility-invite',
  TOOLTIP_COGNITIVE_DECLINE = 'video-content',
  RATE_MUSIC = 'rate-music',
  REFRESH_PLAYLIST = 'refresh-playlist',
  ADD_PROFILES = 'add-profiles',
  SELECT_RESIDENT = 'select-resident',
  INVITE_VERA_USER = 'invite-vera-user',
  REMOVE_TEAM_MEMBER = 'remove-team-member',
  SEND_REMINDER_TO_TEAM_MEMBER = 'send-reminder-to-team-member',
  ADD_TEAM_MEMBER = 'add-team-member',
  REMIND_INVITED_PROFILE = 'remind-invited-profile',
  REMOVE_INVITED_PROFILE = 'remove-invited-profile',
  ADD_PROFILE_SUCCESS = 'add-profile-success',
  ADMIN_PERMISSION = 'admin-permission',
}

export enum EMembershipType {
  HALF_YEAR = 'half_year',
  FULL_YEAR = 'full_year',
}

export const MEMBERSHIP_PRESET = {
  GB: [
    {
      title: 'Semi Annual',
      price: 'GBP£39.99',
      priceByMonth: '£6.66',
      value: EMembershipType.HALF_YEAR,
      period: 'every 6 months',
      description: 'at £6.66/mo billed semi annually.',
    },
    {
      title: 'Annual',
      price: 'GBP£69.99',
      priceByMonth: '£5.83',
      value: EMembershipType.FULL_YEAR,
      period: 'every year',
      description: 'at £5.83/mo billed annually.',
    },
  ],
  US: [
    {
      title: 'Semi Annual',
      price: 'USD$49.99',
      priceByMonth: '$8.33',
      value: EMembershipType.HALF_YEAR,
      period: 'every 6 months',
      description: 'at $8.33/mo billed semi annually.',
    },
    {
      title: 'Annual',
      price: 'USD$89.99',
      priceByMonth: '$7.49',
      value: EMembershipType.FULL_YEAR,
      period: 'every year',
      description: 'at $7.49/mo billed annually.',
    },
  ],
  AU: [
    {
      title: 'Semi Annual',
      price: 'AUD$69.99',
      priceByMonth: '$11.67',
      value: EMembershipType.HALF_YEAR,
      period: 'every 6 months',
      description: 'at AUD$11.67/mo billed semi annually.',
    },
    {
      title: 'Annual',
      price: 'AUD$119.99',
      priceByMonth: '$9.99',
      value: EMembershipType.FULL_YEAR,
      period: 'every year',
      description: 'at AUD$9.99/mo billed annually.',
    },
  ],
  NZ: [
    {
      title: 'Semi Annual',
      price: 'NZD$69.99',
      priceByMonth: '$11.67',
      value: EMembershipType.HALF_YEAR,
      period: 'every 6 months',
      description: 'at NZD$11.67/mo billed semi annually.',
    },
    {
      title: 'Annual',
      price: 'NZD$119.99',
      priceByMonth: '$9.99',
      value: EMembershipType.FULL_YEAR,
      period: 'every year',
      description: 'at NZD$9.99/mo billed annually.',
    },
  ],
  CA: [
    {
      title: 'Semi Annual',
      price: 'CAD$49.99',
      priceByMonth: '$8.33',
      value: EMembershipType.HALF_YEAR,
      period: 'every 6 months',
      description: 'at CAD$8.33/mo billed semi annually.',
    },
    {
      title: 'Annual',
      price: 'CAD$89.99',
      priceByMonth: '$7.49',
      value: EMembershipType.FULL_YEAR,
      period: 'every year',
      description: 'at CAD$7.49/mo billed annually.',
    },
  ],
};

export enum MOOD {
  VERY_GOOD = 'Very Good',
  GOOD = 'Good',
  FAIR = 'Fair',
  POOR = 'Poor',
}

export enum COGNITIVE_DECLINE_LEVEL {
  MILD = 'Mild',
  MODERATE = 'Moderate',
  ADVANCED = 'Advanced',
}

export const countriesOptions = [
  {
    label: 'Australia',
    value: 'AU',
  },
  {
    label: 'Canada',
    value: 'CA',
  },
  {
    label: 'New Zealand',
    value: 'NZ',
  },
  {
    label: 'United Kingdom',
    value: 'GB',
  },
  {
    label: 'United States of America',
    value: 'US',
  },
];

export const profilesFilter = [
  {
    label: 'Membership',
    value: '',
  },
  {
    label: 'Membership - Vera',
    value: 'Vera',
  },
  {
    label: 'Membership - VeraPro',
    value: 'VeraPro',
  },
];

export const healthcareProOptions = [
  {
    label: 'Carer',
    value: 'carer',
  },
  {
    label: 'Music Therapist',
    value: 'music_therapist',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const numberOfClientsOptions = [
  {
    label: '0 - 5',
    value: '0-5',
  },
  {
    label: '6 - 50',
    value: '6-50',
  },
  {
    label: '50 - 100',
    value: '50-100',
  },
  {
    label: '100+',
    value: '100+',
  },
];

export enum VERA_TYPE {
  PRO = 'pro',
  LISTENER = 'listener',
  UNSELECTED = 'unselected',
}

export enum EActivity {
  PLAY = 'play',
  INVITE_SENT = 'invite-sent',
  INVITE_ACCEPTED = 'invite-accepted',
  SONG_LIKE = 'song-like',
  SONG_REMOVED = 'song-removed',
  ARTIST_LIKE = 'artist-like',
  ARTIST_REMOVED = 'artist-removed',
  GENRE_LIKE = 'genre-like',
  GENRE_REMOVED = 'genre-removed',
}

export enum USER_TYPE {
  ACCOUNT = 'account',
  FACILITY_MEMBER = 'facility_member',
  ACCOUNT_ADMIN = 'account_admin',
  UNSELECTED = 'unselected',
}

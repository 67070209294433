import React from 'react';
import {
  Redirect, Route, RouteProps,
} from 'react-router-dom';
import ROUTES from './constants';
import { useAppSelector } from '../store/hooks';
import usePro from '../hooks/usePro';
import { REDUCER_KEY_AUTH } from '../constants';
import AuthLayout from '../layout/AuthLayout';

interface IProRoute extends RouteProps{
  noScroll?: boolean;
  noLayout?: boolean;
}

const ProRoute: React.FunctionComponent<IProRoute> = ({
  component: Component,
  noScroll,
  noLayout,
  ...rest
}) => {
  const { isPro } = usePro();
  const {
    isGuest,
  } = useAppSelector((state) => ({
    isGuest: state[REDUCER_KEY_AUTH].isGuest,
  }));

  if (!Component) return null;

  if (isGuest) {
    return <Redirect to={ROUTES.LOGIN} />;
  }

  if (!isPro) {
    return <Redirect to={ROUTES.HOME} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <AuthLayout noScroll={noScroll} noLayout={noLayout}>
          <Component {...props} />
        </AuthLayout>
      )}
    />
  );
};

export default ProRoute;

import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { throttle } from 'throttle-debounce';
import { refreshAccessToken, clearUser } from '../store/reducers/authReducer';
import { setOpen } from '../store/reducers/modalReducer';

const initialConfig = {
  timeout: 10000,
  validateStatus: (status: number) => true,
};

function handleRequest(config: AxiosRequestConfig) {
  return config;
}

const handleRefreshToken = throttle(3000, (response, store) => {
  if (response.status === 401) {
    if (response.config.url === '/account/token/refresh/') {
      store.dispatch(clearUser({}));
    } else {
      store.dispatch(refreshAccessToken({ refreshToken: store.getState().auth.refreshToken }));
    }
  }
});

function handleResponse(response: AxiosResponse, store: any) {
  const { message } = response.data;

  if (response.status < 400) {
    return response;
  }

  if (response.status === 400) {
    return response;
  }

  if (response.status === 401) {
    handleRefreshToken(response, store);
  }

  if (response.status === 500) {
    store.dispatch(setOpen({ isOpen: true }));
  }

  return Promise.reject(new Error(message || 'Something went wrong'));
}

const handleResponseError = (error: any, store: any) => {
  console.error('LOG---', error);
  // if time out
  if (error.code === 'ECONNABORTED') {
    store.dispatch(setOpen({ isOpen: true }));
  }

  return Promise.reject(new Error(error));
};

export {
  initialConfig as default,
  handleRequest,
  handleResponse,
  handleResponseError,
};

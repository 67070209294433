import React, {
  useEffect,
} from 'react';
import NavBar from '../components/NavBar';
import { useAppSelector } from '../store/hooks';
import {
  REDUCER_KEY_PROFILE,
  REDUCER_KEY_STRIPE,
  REDUCER_KEY_TRACKS,
} from '../constants';
import Preloader from '../components/Preloader';

declare global {
  interface Window {
    FS: any;
    Intercom: any;
  }
}

interface IAuthLayout {
  children: React.ReactNode;
  noScroll?: boolean;
  noLayout?: boolean;
}

const AuthLayout: React.FunctionComponent<IAuthLayout> = ({
  children,
  noScroll,
  noLayout,
}) => {
  const {
    profile,
    isFetching,
    isFetchingPlaylist,
    isFetchingTracks,
    isFetchingStripeKey,
  } = useAppSelector((state) => ({
    profile: state[REDUCER_KEY_PROFILE].profiles[0],
    isFetching: state[REDUCER_KEY_PROFILE].isFetching,
    isFetchingPlaylist: state[REDUCER_KEY_PROFILE].isFetchingPlaylist,
    isFetchingTracks: state[REDUCER_KEY_TRACKS].isFetching,
    isFetchingStripeKey: state[REDUCER_KEY_STRIPE].isFetching,
  }));

  useEffect(() => {
    if (profile && window.FS) {
      window.FS.identify(profile.id, {
        displayName: profile.account_name,
        email: profile.email,
      });
    }
    // eslint-disable-next-line
  }, []);

  if (noLayout) {
    return (
      <>{children}</>
    );
  }

  return (
    <div className="app">
      {
        isFetching
        && (<Preloader notTransparent />)
      }
      {
        (isFetchingPlaylist
          || isFetchingTracks
          || isFetchingStripeKey)
        && (<Preloader />)
      }
      <div className="layout">
        <aside className="layout__aside">
          <NavBar />
        </aside>
        <section className="layout__main">
          <div className="layout-container">
            {
              noScroll
                ? (
                  <>{children}</>
                ) : (
                  <div className="scroll-container">
                    {children}
                  </div>
                )
            }
          </div>
        </section>
      </div>
    </div>
  );
};

export default AuthLayout;

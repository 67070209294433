import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

enum EButtonType {
  DEFAULT = 'default',
  RELAX = 'relax',
  REMINISCE = 'reminisce',
  ENERGISE = 'energise',
}

interface IButton {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  href?: string;
  to?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  color?: 'primary' | 'secondary';
  view?: EButtonType;
  size?: 'small' | 'middle' | 'minimal';
  showLoader?: boolean;
  onClick? (): void;
  id?: string;
}

const Container: React.FunctionComponent<IButton> = ({
  children,
  className,
  disabled,
  href,
  to,
  type,
  view,
  color,
  id,
  onClick,
}) => {
  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {children}
      </a>
    );
  }

  if (to) {
    return (
      <Link
        to={to}
        className={className}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      id={id}
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={className}
    >
      {children}
    </button>
  );
};

const Button: React.FunctionComponent<IButton> = ({
  children,
  className,
  disabled,
  href,
  to,
  type,
  view,
  color,
  onClick,
  size,
  showLoader,
  id,
}) => {
  const attr = {
    id,
    className,
    href,
    to,
    type,
    disabled,
    onClick,
  };

  return (
    <Container
      {...attr}
      className={classNames('btn', {
        [`btn--${color}`]: color,
        [`btn--${view}`]: view,
        [`btn--${size}`]: size,
        [`${className}`]: className,
      })}
      disabled={disabled || showLoader}
    >
      {children}
    </Container>
  );
};

export {
  Button as default,
  EButtonType,
};

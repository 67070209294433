import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { TMenuLink } from '../../types';
import Icons from '../Icons';
import ROUTES from '../../routes/constants';
import { useAppSelector } from '../../store/hooks';
import { REDUCER_KEY_PROFILE } from '../../constants';

interface IMenuLink {
  path?: string;
  className?: string;
  children?: React.ReactNode;
  onClick?(): void;
}

const MenuLink: React.FunctionComponent<IMenuLink> = ({
  path,
  className,
  children,
  onClick,
}) => {
  if (path) {
    return (
      <Link
        to={path}
        className={className}
      >
        {children}
      </Link>
    );
  }
  if (onClick) {
    return (
      <button
        type="button"
        onClick={onClick}
        className={className}
      >
        {children}
      </button>
    );
  }
  return (
    <div className={`${className} main-menu__link--inactive`}>
      {children}
    </div>
  );
};

const MenuItem: React.FunctionComponent<TMenuLink> = ({
  path,
  name,
  iconName,
  children,
  onClickLogout,
  onClickPay,
  onClick,
}) => {
  const history = useHistory();
  const {
    profileData,
  } = useAppSelector((state) => ({
    profileData: state[REDUCER_KEY_PROFILE].profiles[0],
  }));

  return (
    <li className={classNames('main-menu__list-item', {
      'main-menu__list-item--active': path === history.location.pathname,
    })}
    >
      <MenuLink
        path={path}
        onClick={onClick}
        className={classNames('main-menu__link', {
          'main-menu__link--active': path === history.location.pathname,
        })}
      >
        <span className="main-menu__link-container">
          <Icons name={iconName} className="main-menu__icon" />
          <span className="main-menu__link-text">{name}</span>
        </span>
      </MenuLink>
      {
        children
        && (
          <ul className="main-menu__sub-list">
            {
              children?.map((child) => (
                <li
                  key={child.name}
                  className={classNames('main-menu__list-item main-menu__sub-item', {
                    'main-menu__list-item--active': child.path && history.location.pathname.includes(child.path),
                  })}
                >
                  {
                    (!profileData?.subscribed && child.name === 'Membership')
                      ? (
                        <button
                          type="button"
                          onClick={onClickPay}
                          className="main-menu__link"
                        >
                          <span className="main-menu__link-container">
                            {child.name}
                          </span>
                        </button>
                      ) : (
                        <MenuLink
                          path={child.path}
                          className={classNames('main-menu__link', {
                            'main-menu__link--relax': child.path === ROUTES.RELAX,
                            'main-menu__link--reminisce': child.path === ROUTES.REMINISCE,
                            'main-menu__link--energise': child.path === ROUTES.ENERGISE,
                            'main-menu__link--active': child.path && history.location.pathname.includes(child.path),
                            'main-menu__link--disabled': child.disabled,
                          })}
                        >
                          <span className="main-menu__link-container">
                            {child.name}
                          </span>
                        </MenuLink>
                      )
                  }
                </li>
              ))
            }
            {
              name === 'Account'
              && (
                <li className="main-menu__list-item main-menu__sub-item">
                  <button
                    className="main-menu__link"
                    onClick={onClickLogout}
                  >
                    <span className="main-menu__link-container">
                      Logout
                    </span>
                  </button>
                </li>
              )
            }
          </ul>
        )
      }
    </li>
  );
};

export default MenuItem;

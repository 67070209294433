import { useEffect, useState } from 'react';
import { useAppSelector } from '../store/hooks';
import { REDUCER_KEY_AUTH, USER_TYPE, VERA_TYPE } from '../constants';

const usePro = () => {
  const { userType, veraType } = useAppSelector((state) => ({
    userType: state[REDUCER_KEY_AUTH].userType,
    veraType: state[REDUCER_KEY_AUTH].veraType,
  }));
  const [isPro, setIsPro] = useState(veraType === VERA_TYPE.PRO);

  useEffect(() => {
    if (veraType === VERA_TYPE.PRO) {
      setIsPro(true);
    }
  }, [veraType]);

  return {
    isPro,
  };
};

export default usePro;

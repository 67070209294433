import {
  configureStore,
  ThunkAction,
  Action,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import {
  REDUCER_KEY_AUTH,
  REDUCER_KEY_PROFILE,
  REDUCER_KEY_COMMON,
  REDUCER_KEY_ARTISTS,
  REDUCER_KEY_GENRES,
  REDUCER_KEY_PLAYER,
  REDUCER_KEY_TRACKS,
  REDUCER_KEY_LANGUAGES,
  REDUCER_KEY_COUNTRIES,
  REDUCER_KEY_STRIPE,
  REDUCER_KEY_MODAL,
  REDUCER_KEY_CHAT,
  ACTION_NAME_FETCH_FAVOURITE_GENRES,
  ACTION_NAME_FETCH_FAVOURITE_ARTISTS,
  ACTION_NAME_FETCH_GENRES,
  ACTION_NAME_UPDATE_FAVOURITE_GENRES,
  ACTION_NAME_UPDATE_FAVOURITE_ARTISTS,
  ACTION_NAME_FETCH_PROFILES,
  ACTION_NAME_FETCH_PLAYLISTS,
  ACTION_NAME_FETCH_LICENSE_TOKEN,
  ACTION_NAME_FETCH_TRACKS_PREFERENCES,
  ACTION_NAME_FETCH_PROFILE_DATA,
  ACTION_NAME_UPDATE_TRACKS_PREFERENCES,
  ACTION_NAME_REFRESH_PLAYLISTS,
  REDUCER_KEY_SETTINGS,
  REDUCER_KEY_ACTIVITY,
  REDUCER_KEY_FACILITY,
} from '../constants';
import authReducer from './reducers/authReducer';
import profileReducer from './reducers/profileReducer';
import commonReducer from './reducers/commonReducer';
import artistsReducer from './reducers/artistsReducer';
import genresReducer from './reducers/genresReducer';
import playerReducer from './reducers/playerReducer';
import tracksReducer from './reducers/tracksReducer';
import languagesReducer from './reducers/languagesReducer';
import countriesReducer from './reducers/countriesReducer';
import stripeReducer from './reducers/stripeReducer';
import modalReducer from './reducers/modalReducer';
import settingsReducer from './reducers/settingsReducer';
import chatReducer from './reducers/chatReducer';
import activityReducer from './reducers/activityReducer';
import facilityReducer from './reducers/facilityReducer';

const initialState = {};

const store = configureStore({
  reducer: {
    [REDUCER_KEY_AUTH]: authReducer,
    [REDUCER_KEY_PROFILE]: profileReducer,
    [REDUCER_KEY_COMMON]: commonReducer,
    [REDUCER_KEY_ARTISTS]: artistsReducer,
    [REDUCER_KEY_GENRES]: genresReducer,
    [REDUCER_KEY_PLAYER]: playerReducer,
    [REDUCER_KEY_TRACKS]: tracksReducer,
    [REDUCER_KEY_LANGUAGES]: languagesReducer,
    [REDUCER_KEY_COUNTRIES]: countriesReducer,
    [REDUCER_KEY_STRIPE]: stripeReducer,
    [REDUCER_KEY_MODAL]: modalReducer,
    [REDUCER_KEY_SETTINGS]: settingsReducer,
    [REDUCER_KEY_CHAT]: chatReducer,
    [REDUCER_KEY_ACTIVITY]: activityReducer,
    [REDUCER_KEY_FACILITY]: facilityReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        FLUSH,
        REHYDRATE,
        PAUSE,
        PERSIST,
        PURGE,
        REGISTER,
        `${REDUCER_KEY_PROFILE}/${ACTION_NAME_FETCH_PROFILES}/fulfilled`,
        `${REDUCER_KEY_PROFILE}/${ACTION_NAME_FETCH_PLAYLISTS}/fulfilled`,
        `${REDUCER_KEY_PROFILE}/${ACTION_NAME_FETCH_PROFILE_DATA}/fulfilled`,
        `${REDUCER_KEY_PROFILE}/${ACTION_NAME_REFRESH_PLAYLISTS}/fulfilled`,
        `${REDUCER_KEY_ARTISTS}/${ACTION_NAME_FETCH_FAVOURITE_ARTISTS}/fulfilled`,
        `${REDUCER_KEY_ARTISTS}/${ACTION_NAME_UPDATE_FAVOURITE_ARTISTS}/fulfilled`,
        `${REDUCER_KEY_GENRES}/${ACTION_NAME_FETCH_GENRES}/fulfilled`,
        `${REDUCER_KEY_GENRES}/${ACTION_NAME_FETCH_FAVOURITE_GENRES}/fulfilled`,
        `${REDUCER_KEY_GENRES}/${ACTION_NAME_UPDATE_FAVOURITE_GENRES}/fulfilled`,
        `${REDUCER_KEY_PLAYER}/${ACTION_NAME_FETCH_LICENSE_TOKEN}/fulfilled`,
        `${REDUCER_KEY_TRACKS}/${ACTION_NAME_FETCH_TRACKS_PREFERENCES}/fulfilled`,
        `${REDUCER_KEY_TRACKS}/${ACTION_NAME_UPDATE_TRACKS_PREFERENCES}/fulfilled`,
      ],
    },
  }),
  preloadedState: initialState,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
  RootState,
  unknown,
  Action<string>>;

const persistor = persistStore(store);

export {
  store as default,
  persistor,
};

import AxiosClient, { AxiosClientWithoutTimeout } from './AxiosClient';
import {
  IProfile,
  IRate,
  ITeamMember,
  IProfilePro,
  IExistingResident,
} from '../types';

class ApiClient {
  setToken = (access: string, refresh?: string) => {
    AxiosClient.defaults.headers.common.Authorization = `Bearer ${access}`;
    AxiosClientWithoutTimeout.defaults.headers.common.Authorization = `Bearer ${access}`;
  }

  // account
  logout = (refresh: string) => AxiosClient.post('/account/signout/', { refresh });

  logIn = ({ email, password }: { email: string, password: string }) => AxiosClient.post('/account/token/', { email, password });

  signUp = (
    {
      id,
      name,
      email,
      password,
    }: {
      id: string,
      name: string,
      email: string,
      password: string,
    },
  ) => AxiosClientWithoutTimeout.post('/account/signup/', {
    id,
    name,
    email,
    password,
  });

  refreshUserToken = (refresh: string) => AxiosClient.post('/account/token/refresh/', { refresh });

  notify = (
    {
      email,
      country,
    }: {
      email: string,
      country: string,
    },
  ) => AxiosClient.post('/account/notify/', {
    email,
    country,
  });

  verifyEmailRequiring = () => AxiosClient.post('/account/send-verification-email/');

  verifyEmail = (token: string) => AxiosClient.post(`/account/verify-email/${token}/`);

  // password
  forgotPassword = (email: string) => AxiosClient.post('/account/password_forgot/', { email });

  resetPassword = (
    {
      password,
      confirm_password,
    }: {
      password: string,
      confirm_password: string,
    },
  ) => AxiosClient.post('/account/password_reset/', {
    password,
    confirm_password,
  });

  updatePassword = (
    {
      current_password,
      new_password,
      confirm_password,
    }: {
      current_password: string,
      new_password: string,
      confirm_password: string,
    },
  ) => AxiosClient.post('/account/password_update/', {
    current_password,
    new_password,
    confirm_password,
  });

  // social
  googleLogin = (access_token: string) => AxiosClient.post('/account/rest-auth/google/', { access_token })

  facebookLogin = (access_token: string) => AxiosClient.post('/account/rest-auth/facebook/', { access_token })

  appleLogin = (access_token: string) => AxiosClient.post('/account/rest-auth/apple/', { access_token })

  // guest admin account
  fetchAdminAccount = () => AxiosClient.get('/account/admin/');

  removeAdminAccount = () => AxiosClient.delete('/account/admin/');

  createAdminAccount = (email: string, name: string) => AxiosClient.post('/account/admin/', {
    email,
    name,
  });

  updateAdminAccount = (email: string, name: string) => AxiosClient.put('/account/admin/', {
    email,
    name,
  });

  setPasswordAdminAccount = (
    password: string,
    token: string,
    email: string,
    name: string,
  ) => AxiosClient.put('/account/admin/sign-up', {
    password,
    token,
    email,
    name,
  });

  // account facility
  fetchAccountFacility = () => AxiosClient.get('/account/facility/');

  searchFacilities = (keyword: string) => AxiosClient.get(`/facilities/?search=${keyword}`);

  createAccountFacility = (facility: string) => AxiosClient.post('/account/facility/', { facility });

  removeAccountFacility = () => AxiosClient.delete('/account/facility/');

  inviteAccountFacility = (email: string) => AxiosClient.post('/account/facility-invite/', { email });

  // profile

  getAccountAdminInfo = (token: string) => AxiosClient.get(`/account/admin/sign-up/?token=${token}`);

  putAccountAdminLocation = (location: { location: string }) => AxiosClient.put('/account/admin/location/', location);

  fetchProfiles = () => AxiosClient.get('/profiles/');

  fetchProfileData = (id: string) => AxiosClient.get(`/profiles/${id}/`);

  fetchProfilePhoto = (id: string) => AxiosClient.get(`/profiles/${id}/photo/`);

  updateProfilePhoto = (id: string, data: FormData) => AxiosClient.put(`/profiles/${id}/photo/`, data);

  patchProfilePhoto = (id: string, data: FormData) => AxiosClient.patch(`/profiles/${id}/photo/`, data);

  createProfile = (profileData: IProfile) => AxiosClientWithoutTimeout.post('/profiles/', { ...profileData });

  patchProfileData = (id: string, profileData: IProfile) => AxiosClient.patch(`/profiles/${id}/`, { ...profileData });

  updateProfileData = (id: string, profileData: IProfile) => AxiosClient.put(`/profiles/${id}/`, { ...profileData });

  deleteProfile = (id: string) => AxiosClient.delete(`/profiles/${id}/`);

  fetchPlaylistDetails = (profile_id: string, playlist_id: string) => AxiosClient.get(`/profiles/${profile_id}/playlists/${playlist_id}/`);

  refreshPlaylistDetails = (profile_id: string, playlist_id: string) => AxiosClientWithoutTimeout.post(`/profiles/${profile_id}/playlists/${playlist_id}/refresh/`);

  generatePlaylists = (profile_id: string) => AxiosClientWithoutTimeout.post(`/profiles/${profile_id}/playlists/`);

  fetchLicenseToken = ({
    drm_type,
    track_id,
  }: { drm_type: string, track_id: string }) => AxiosClient.post('/profiles/license_token/', {
    drm_type,
    track_id,
  });

  // artists
  searchArtists = (
    profile_id: string,
    keywords: string,
    exclude_artists?: Array<string>,
  ) => AxiosClient.post(`/profiles/${profile_id}/search_artists/`, { keywords, exclude_artists });

  fetchFavouriteArtistes = (profile_id: string) => AxiosClient.get(`/profiles/${profile_id}/artists/`);

  updateFavouriteArtists = (profile_id: string, action: string, artists: Array<string>, comment?: string) => AxiosClient.post(`/profiles/${profile_id}/artists/`, { action, artists, comment });

  fetchSuggestedArtistes = (
    profile_id: string,
    exclude_artists?: Array<string>,
    keywords?: string,
  ) => AxiosClient.post(`/profiles/${profile_id}/search_artists/`, { exclude_artists, keywords: keywords || '' });

  // activity
  fetchLatestActivity = ({
    profile_id,
    offset = 0,
    limit = 0,
  }: {
    profile_id: string,
    offset: number,
    limit: number,
  }) => AxiosClient.get(`/profiles/${profile_id}/activity/?offset=${offset}&limit=${limit}`);

  // genres
  fetchGenres = () => AxiosClient.get('/genres/');

  fetchFavouriteGenres = (profile_id: string) => AxiosClient.get(`/profiles/${profile_id}/genres/`);

  updateFavouriteGenres = (profile_id: string, action: string, genres: Array<string>) => AxiosClient.post(`/profiles/${profile_id}/genres/`, { action, genres });

  // tracks
  fetchTracksPreferences = (profile_id: string) => AxiosClient.get(`/profiles/${profile_id}/tracks/`);

  updateTracksPreferences = (profile_id: string, action: string, tracks: Array<string>, comment?: string) => AxiosClient.post(`/profiles/${profile_id}/tracks/`, { action, tracks, comment });

  // feedback
  addFeedback = (profile_id: string, rate: IRate) => AxiosClient.post(`/profiles/${profile_id}/feedback/`, { ...rate });

  // languages
  fetchLanguages = () => AxiosClient.get('/languages/');

  // countries
  fetchCountries = () => AxiosClient.get('/countries/');

  // stripe
  checkoutStripe = (membership_type: string, location: string) => AxiosClient.post('/stripe/checkout/', { membership_type, location });

  fetchPortalLink = () => AxiosClient.post('/stripe/portal/');

  // detect country

  getCurrentLocation = () => AxiosClient.get('https://ipapi.co/json/');

  // PRO
  // members
  fetchTeamMembers = (facility_id: string) => AxiosClient.get(`/api/v1/vera-pro/facilities/${facility_id}/members/`);

  addTeamMember = (
    facility_id: string,
    name: string,
    email: string,
  ) => AxiosClient.post(`/api/v1/vera-pro/facilities/${facility_id}/members/`, { name, email });

  removeTeamMember = (facility_id: string, member_id: string) => AxiosClient.delete(`/api/v1/vera-pro/facilities/${facility_id}/members/${member_id}/`);

  sendReminderToTeamMember = (
    facility_id: string,
    member_id: string,
  ) => AxiosClient.post(`/api/v1/vera-pro/facilities/${facility_id}/members/${member_id}/remind_invitation/`);

  fetchMembershipDetails = () => AxiosClient.get('/api/v1/account/membership/');

  setMemberPassword = (
    facility_id: string,
    member_id: string,
    token: string,
    password: string,
    email: string,
  ) => AxiosClient.patch(`/api/v1/vera-pro/facilities/${facility_id}/members/${member_id}/set_password/`, { token, password, email });

  fetchMemberEmail = (
    facility_id: string,
    member_id: string,
    token: string,
  ) => AxiosClient.get(`/api/v1/vera-pro/facilities/${facility_id}/members/${member_id}/set_password/?token=${token}`)

  patchMemberData = (
    facility_id: string,
    member_id: string,
    email?: string,
    id?: string,
    name?: string,
    location?: string,
    health_care_type?: string,
  ) => AxiosClient.patch('/api/v1/vera-pro/member-profile/', {
    email,
    id,
    name,
    location,
    health_care_type,
  });

  // signup pro

  signUpPro = (
    {
      name,
      email,
      password,
    }: {
      name: string,
      email: string,
      password: string,
    },
  ) => AxiosClientWithoutTimeout.post('/api/v1/vera-pro/member-signup/', {
    name,
    email,
    password,
  });

  // create profile pro

  createProfilePro = (profileProData: IProfilePro) => AxiosClient.post('/api/v1/vera-pro/facilities/sign-up/', { ...profileProData });

  // fetching PRO user data

  fetchProUserData = () => AxiosClient.get('/api/v1/vera-pro/member-profile/');

  patchProUserData = (name: string, email: string) => AxiosClient.patch('/api/v1/vera-pro/member-profile/', { name, email });

  // fetching facility data
  fetchFacilityData = (facility_id: string) => AxiosClient.get(`/api/v1/vera-pro/facilities/${facility_id}/profile/`);

  patchFacilityData = (
    facility_id: string,
    name?: string,
    location?: string,
    postcode?: string,
    website?: string,
    residents?: string,
    health_care_type?: string,
    city?: string,
  ) => AxiosClient.patch(`/api/v1/vera-pro/facilities/${facility_id}/profile/`, {
    name,
    location,
    postcode,
    website,
    residents,
    health_care_type,
    city,
  });

  // profiles
  addNewResidentProfile = (profile: IProfile) => AxiosClient.post('/api/v1/profiles/add-resident/', { ...profile });

  addExistingResident = (facility_id: string, resident: IExistingResident) => AxiosClient.post(`/api/v1/vera-pro/facilities/${facility_id}/invite-resident/`, { ...resident });

  remindInvitedResident = (facility_id: string, invite_id: string) => AxiosClient.post(`/api/v1/vera-pro/facilities/${facility_id}/invite-resident/${invite_id}/remind/`);

  deleteInvitedResident = (profile_id: string) => AxiosClient.delete(`/api/v1/profiles/remove-resident/${profile_id}/`);
}

export default new ApiClient();

import React from 'react';
import {
  Redirect,
  Route,
  RouteProps,
  useHistory,
} from 'react-router-dom';
import ROUTES from './constants';
import { useAppSelector } from '../store/hooks';
import { REDUCER_KEY_AUTH } from '../constants';
import AuthLayout from '../layout/AuthLayout';

const PrivateRoute: React.FunctionComponent<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const {
    auth,
  } = useAppSelector((state) => ({
    auth: state[REDUCER_KEY_AUTH],
  }));
  const history = useHistory();

  if (!Component) return null;

  if (
    auth.isRegistering
    && history.location.pathname !== ROUTES.LOGIN
    && history.location.pathname !== ROUTES.SIGNUP
  ) {
    return <Redirect to={ROUTES.CONTINUE_ONBOARDING} />;
  }

  if (auth.isGuest) {
    return <Redirect to={ROUTES.LOGIN} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <AuthLayout>
          <Component {...props} />
        </AuthLayout>
      )}
    />
  );
};

export default PrivateRoute;

import React from 'react';
import Button, { EButtonType } from '../Button';
import OverlayPlayerBanner from './index';

interface IEnergiseOverlayPlayerBanner {
  onStartListening(): void;
  onShufflePlay(): void;
}

const EnergiseOverlayPlayerBanner: React.FunctionComponent<IEnergiseOverlayPlayerBanner> = ({
  onStartListening,
  onShufflePlay,
}) => (
  <OverlayPlayerBanner
    title="Energise"
    text="The right music to help you live your day to the fullest"
    image="/images/energise.png"
  >
    <div className="greeting-banner__buttons">
      <div className="row">
        <div className="col-auto">
          <Button
            type="button"
            color="primary"
            size="middle"
            view={EButtonType.ENERGISE}
            className="greeting-banner__button"
            onClick={onStartListening}
          >
            Start Listening
          </Button>
        </div>
        <div className="col-auto">
          <Button
            type="button"
            color="secondary"
            size="middle"
            view={EButtonType.ENERGISE}
            className="greeting-banner__button"
            onClick={onShufflePlay}
          >
            Shuffle Play
          </Button>
        </div>
      </div>
    </div>
  </OverlayPlayerBanner>
);

export default EnergiseOverlayPlayerBanner;

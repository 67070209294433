import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import {
  PrivateRoute,
  GuestRoute,
  MusicPlayerRoute,
  ProRoute,
} from './routes';
import ROUTES from './routes/constants';
import { AsyncDashboard } from './containers/Dashboard/async';
import { AsyncRelax } from './containers/Relax/async';
import { AsyncReminisce } from './containers/Reminisce/async';
import { AsyncEnergise } from './containers/Energise/async';
import { AsyncLogin } from './containers/Login/async';
import { AsyncLoginPro } from './containers/LoginPro/async';
import { AsyncSignup } from './containers/Signup/async';
import { AsyncSignupPro } from './containers/SignupPro/async';
import { AsyncForgotPassword } from './containers/ForgotPassword/async';
import { AsyncResetPassword } from './containers/ResetPassword/async';
import { AsyncPersonalInfo } from './containers/PersonalInfo/async';
import { AsyncMusicPreferences } from './containers/MusicPreferences/async';
import { AsyncMembership } from './containers/Membership/async';
import { AsyncSettingsPage } from './containers/SettingsPage/async';
import { AsyncPassword } from './containers/SettingsPage/Password/async';
import { AsyncGuestAdmin } from './containers/SettingsPage/SettingsUser/GuestAdmin/async';
import { AsyncShareProfile } from './containers/SettingsPage/SettingsUser/ShareProfile/async';
import { AsyncTeamMembers } from './containers/SettingsPage/SettingsProUser/TeamMembers/async';
import { AsyncThankYou } from './containers/ThankYou/async';
import { AsyncPlaylist } from './containers/Playlist/async';
import { AsyncProfile } from './containers/Profile/async';
import { AsyncGoodNews } from './containers/GoodNews/async';
import { AsyncPaymentSuccess } from './containers/PaymentSuccess/async';
import { AsyncPaymentCancelled } from './containers/PaymentCancelled/async';
import { useAppSelector, useAppDispatch } from './store/hooks';
import { REDUCER_KEY_AUTH, REDUCER_KEY_PROFILE, VERA_TYPE } from './constants';
import ApiClient from './api/ApiClient';
import {
  fetchProfiles,
  fetchProUserProfileData,
} from './store/reducers/profileReducer';
import { AsyncLoggedOut } from './containers/LoggedOut/async';
import Preloader from './components/Preloader';
import { AsyncContinueOnboarding } from './containers/ContinueOnboarding/async';
import { AsyncVerifyEmail } from './containers/VerifyEmail/async';
import ErrorModal from './components/ModalWindow/ErrorModal';
import { AsyncProfileCreation1 } from './containers/ProfileCreation1/async';
import { AsyncProfileCreation2 } from './containers/ProfileCreation2/async';
import { AsyncProfileCreation3 } from './containers/ProfileCreation3/async';
import { AsyncProfileCreation4 } from './containers/ProfileCreation4/async';
import { AsyncProfileCreation5 } from './containers/ProfileCreation5/async';
import { AsyncProfileCreation6 } from './containers/ProfileCreation6/async';
import { AsyncNotifyMe } from './containers/NotifyMe/async';
import { AsyncProfileCreationPro1 } from './containers/ProfileCreationPro1/async';
import { AsyncProfileCreationPro2 } from './containers/ProfileCreationPro2/async';
import { AsyncProfileCreationPro3 } from './containers/ProfileCreationPro3/async';
import { AsyncProfileCreationProSuccess } from './containers/ProfileCreationProSuccess/async';
import { AsyncProfiles } from './containers/Profiles/async';
import { AsyncProfilesDetail } from './containers/ProfilesDetail/async';
import { AsyncProfileCreationMembership } from './containers/ProfileCreationMembership/async';
import { AsyncWelcomeToVera } from './containers/WelcomeToVera/async';
import { AsyncProfileCreationGuestAdmin1 } from './containers/ProfileCreationGuestAdmin1/async';
import { AsyncProfileCreationGuestAdmin2 } from './containers/ProfileCreationGuestAdmin2/async';
import { AsyncProfileCreationFacilityMember1 } from './containers/ProfileCreationFacilityMember1/async';
import { AsyncProfileCreationFacilityMember2 } from './containers/ProfileCreationFacilityMember2/async';
import { AsyncManage } from './containers/Manage/async';
import { AsyncProfilesNewResidentCreation } from './containers/ProfilesNewResidentCreation/async';
import usePro from './hooks/usePro';

function App() {
  const [isReady, setIsReady] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { isPro } = usePro();
  const {
    token,
    refreshToken,
    isGuest,
    isRegistering,
    veraType,
    profileId,
  } = useAppSelector((state) => ({
    isGuest: state[REDUCER_KEY_AUTH].isGuest,
    token: state[REDUCER_KEY_AUTH].token,
    refreshToken: state[REDUCER_KEY_AUTH].refreshToken,
    isRegistering: state[REDUCER_KEY_AUTH].isRegistering,
    veraType: state[REDUCER_KEY_AUTH].veraType,
    profileId: state[REDUCER_KEY_PROFILE].data.id,
  }));

  useEffect(() => {
    if (!isGuest || isRegistering) {
      ApiClient.setToken(token, refreshToken);
      dispatch(fetchProfiles());
      if (veraType === VERA_TYPE.PRO) {
        dispatch(fetchProUserProfileData());
      }
    }
    // eslint-disable-next-line
  }, [token, isGuest]);

  setTimeout(() => setIsReady(true), 1000);

  return (
    <Router
      forceRefresh={false}
    >
      {
        isReady
          ? (
            <Switch>
              <GuestRoute exact path={`${ROUTES.LOGIN}`} component={AsyncLogin} />
              <GuestRoute exact path={`${ROUTES.LOGIN_PRO}`} component={AsyncLoginPro} />
              <GuestRoute exact path={`${ROUTES.SIGNUP}`} component={AsyncSignup} />
              <GuestRoute exact path={`${ROUTES.SIGNUP_PRO}`} component={AsyncSignupPro} />
              <GuestRoute exact path={`${ROUTES.FORGOT_PASSWORD}`} component={AsyncForgotPassword} />
              <GuestRoute exact path={`${ROUTES.RESET_PASSWORD}`} component={AsyncResetPassword} />
              <Route exact path={`${ROUTES.PROFILE_CREATION_1}`} component={AsyncProfileCreation1} />
              <Route exact path={`${ROUTES.PROFILE_CREATION_2}`} component={AsyncProfileCreation2} />
              <Route exact path={`${ROUTES.PROFILE_CREATION_3}`} component={AsyncProfileCreation3} />
              <Route exact path={`${ROUTES.PROFILE_CREATION_4}`} component={AsyncProfileCreation4} />
              <Route exact path={`${ROUTES.PROFILE_CREATION_5}`} component={AsyncProfileCreation5} />
              <Route exact path={`${ROUTES.PROFILE_CREATION_6}`} component={AsyncProfileCreation6} />
              <Route exact path={`${ROUTES.PROFILE_CREATION_MEMBERSHIP}`} component={AsyncProfileCreationMembership} />
              <Route exact path={`${ROUTES.NOTIFY_ME}`} component={AsyncNotifyMe} />
              <Route exact path={`${ROUTES.CONTINUE_ONBOARDING}`} component={AsyncContinueOnboarding} />
              <GuestRoute exact path={`${ROUTES.THANK_YOU}`} component={AsyncThankYou} />
              <GuestRoute exact path={`${ROUTES.GOOD_NEWS}`} component={AsyncGoodNews} />
              <GuestRoute exact path={`${ROUTES.LOGOUT}`} component={AsyncLoggedOut} />
              <PrivateRoute exact path="/" component={AsyncDashboard} />
              <MusicPlayerRoute exact path={`${ROUTES.PLAYLIST}`} component={AsyncPlaylist} />
              <MusicPlayerRoute exact path={`${ROUTES.RELAX}`} component={AsyncRelax} />
              <MusicPlayerRoute exact path={`${ROUTES.REMINISCE}`} component={AsyncReminisce} />
              <MusicPlayerRoute exact path={`${ROUTES.ENERGISE}`} component={AsyncEnergise} />
              <MusicPlayerRoute exact path={`${ROUTES.PROFILE}`} component={AsyncProfile} />
              <MusicPlayerRoute exact path={`${ROUTES.PERSONAL_INFO}`} component={AsyncPersonalInfo} />
              <MusicPlayerRoute path={`${ROUTES.MUSIC_PREFERENCES}`} component={AsyncMusicPreferences} />
              <MusicPlayerRoute exact path={`${ROUTES.ACCOUNT}`} component={() => <Redirect to={ROUTES.MEMBERSHIP} />} />
              <MusicPlayerRoute exact path={`${ROUTES.MEMBERSHIP}`} component={AsyncMembership} />
              <MusicPlayerRoute exact path={`${ROUTES.SETTINGS}`} component={AsyncSettingsPage} />
              <MusicPlayerRoute exact path={`${ROUTES.SETTINGS_PASSWORD}`} component={AsyncPassword} />
              <MusicPlayerRoute exact path={`${ROUTES.SETTINGS_GUEST_ADMIN}`} component={AsyncGuestAdmin} />
              <MusicPlayerRoute exact path={`${ROUTES.SETTINGS_SHARE_PROFILE}`} component={AsyncShareProfile} />
              <ProRoute exact path={`${ROUTES.SETTINGS_TEAM_MEMBERS}`} component={AsyncTeamMembers} />
              <Route exact path={`${ROUTES.PAYMENT_SUCCESS}`} component={AsyncPaymentSuccess} />
              <Route exact path={`${ROUTES.PAYMENT_CANCELLED}`} component={AsyncPaymentCancelled} />
              <Route exact path={`${ROUTES.VERIFY_EMAIL}`} component={AsyncVerifyEmail} />
              <Route exact path={`${ROUTES.PROFILE_CREATION_PRO_1}`} component={AsyncProfileCreationPro1} />
              <Route exact path={`${ROUTES.PROFILE_CREATION_PRO_2}`} component={AsyncProfileCreationPro2} />
              <Route exact path={`${ROUTES.PROFILE_CREATION_PRO_3}`} component={AsyncProfileCreationPro3} />
              <Route exact path={`${ROUTES.PROFILE_CREATION_PRO_SUCCESS}`} component={AsyncProfileCreationProSuccess} />
              <ProRoute exact path={ROUTES.PROFILES} component={AsyncProfiles} noScroll />
              <ProRoute
                exact
                path={ROUTES.PROFILES_ADD_PROFILE}
                component={AsyncProfilesNewResidentCreation}
                noLayout
              />
              <ProRoute exact path={`${ROUTES.PROFILES_DETAIL}/:id`} component={AsyncProfilesDetail} />
              <ProRoute exact path={ROUTES.MANAGE} component={AsyncManage} />
              <GuestRoute exact path={`${ROUTES.WELCOME_TO_VERA}`} component={AsyncWelcomeToVera} />
              <Route exact path={`${ROUTES.SIGNUP_GUEST_ADMIN}`} component={AsyncSignup} />
              <Route exact path={`${ROUTES.PROFILE_CREATION_GUEST_ADMIN_1}`} component={AsyncProfileCreationGuestAdmin1} />
              <Route exact path={`${ROUTES.PROFILE_CREATION_GUEST_ADMIN_2}`} component={AsyncProfileCreationGuestAdmin2} />
              <Route exact path={`${ROUTES.PROFILE_CREATION_FACILITY_MEMBER_1}`} component={AsyncProfileCreationFacilityMember1} />
              <Route exact path={`${ROUTES.PROFILE_CREATION_FACILITY_MEMBER_2}`} component={AsyncProfileCreationFacilityMember2} />
            </Switch>
          ) : (
            <Preloader />
          )
      }
      <ErrorModal />
    </Router>
  );
}

export default App;

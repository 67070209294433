import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import Menu from './Menu';
import Logo from '../Logo';
import ROUTES from '../../routes/constants';
import { useAppSelector } from '../../store/hooks';
import { REDUCER_KEY_PROFILE } from '../../constants';
import Icon, { ICON_NAMES } from '../Icons';
import useCommonState from '../../hooks/useCommonState';

const NavBar = () => {
  const [isChatOpen, setChatOpen] = useState(false);
  const [isDelayPassed, setIsDelayPassed] = useState(false);
  const { profile } = useAppSelector((state) => ({
    profile: state[REDUCER_KEY_PROFILE].profiles[0],
  }));
  const { isMobile } = useCommonState();

  const bootWithProps = useCallback(() => {
    if (profile) {
      window.Intercom('boot', {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        name: profile.account_name, // Full name
        email: profile.email, // Email address
        created_at: moment(profile.created_at).unix(), // Signup date as a Unix timestamp
      });
      window.Intercom('show');
    }
  }, [profile]);

  const handleToggleChat = useCallback(() => {
    setChatOpen((prevValue) => !prevValue);
    if (isChatOpen) {
      setIsDelayPassed(!isDelayPassed);
    } else {
      setTimeout(() => setIsDelayPassed(!isDelayPassed), 2000);
    }
  }, [isDelayPassed, isChatOpen]);

  useEffect(() => {
    if (isChatOpen) {
      if (profile) {
        bootWithProps();
      }
    } else {
      window.Intercom('hide');
      window.Intercom('shutdown');
    }
    // eslint-disable-next-line
  }, [isChatOpen]);

  return (
    <div className="nav-bar">
      <div className="nav-bar__header">
        <Link className="nav-bar__logo" to={ROUTES.HOME}><Logo /></Link>
        <Menu toggleChat={handleToggleChat} isChatOpen={isDelayPassed} />
      </div>
      <div className="btn-chat-background" hidden={!isChatOpen || isMobile} />
      <button
        className={classNames('btn--chat btn-chat', {
          'btn-chat--open': isDelayPassed,
        })}
        onClick={handleToggleChat}
      >
        <span className="btn-chat__icon-container">
          {
            isDelayPassed
              ? (<Icon name={ICON_NAMES.CLOSE_BIG} className="icon--chat btn-chat__icon" />)
              : (<Icon name={ICON_NAMES.CHAT} className="icon--chat btn-chat__icon" />)
          }
        </span>
        <span className="btn-chat__text">
          {
            isDelayPassed
              ? (
                <span className="btn-chat__title">Close chat</span>
              ) : (
                <>
                  <span className="btn-chat__title">Need a hand?</span>
                  <span className="btn-chat__subtitle">Speak with Vera support</span>
                </>
              )
          }
        </span>
      </button>
    </div>
  );
};

export default NavBar;

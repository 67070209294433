import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  REDUCER_KEY_LANGUAGES,
  ACTION_NAME_FETCH_LANGUAGES,
} from '../../constants';
import ApiClient from '../../api/ApiClient';
import { ILanguages } from '../../types';

interface ILanguagesState {
  isFetching: boolean;
  languages: Array<ILanguages>;
}

const initialState: ILanguagesState = {
  isFetching: false,
  languages: [],
};

const fetchLanguages = createAsyncThunk<AxiosResponse<Array<ILanguages>>>(
  `${REDUCER_KEY_LANGUAGES}/${ACTION_NAME_FETCH_LANGUAGES}`,
  async () => {
    const response = await ApiClient.fetchLanguages();
    return response;
  },
);

const languagesSlice = createSlice<ILanguagesState, SliceCaseReducers<ILanguagesState>,
  typeof REDUCER_KEY_LANGUAGES>({
    name: REDUCER_KEY_LANGUAGES,
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchLanguages.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(fetchLanguages.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(fetchLanguages.fulfilled, (state, { payload }) => {
          state.languages = payload.data;
          state.isFetching = false;
        });
    },
  });

export {
  fetchLanguages,
};

export default languagesSlice.reducer;

import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ModalWindow, { ModalTitle } from './index';
import Button from '../Button';
import RadioButtonCard from '../RadioButtonCard';
import {
  EMembershipType,
  MEMBERSHIP_PRESET,
  REDUCER_KEY_PROFILE,
  REDUCER_KEY_STRIPE,
} from '../../constants';
import { checkoutPayment } from '../../store/reducers/stripeReducer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

interface IStartTrialModal {
  modalIsOpen: boolean;
  toggleModalOpen(): void;
}

interface IStartTrial {
  membership_type: string;
}

const StartTrialModal: React.FunctionComponent<IStartTrialModal> = ({
  modalIsOpen,
  toggleModalOpen,
}) => {
  const {
    isFetching,
    profileInfo,
  } = useAppSelector((state) => ({
    isFetching: state[REDUCER_KEY_STRIPE].isFetching,
    profileInfo: state[REDUCER_KEY_PROFILE].profiles[0],
  }));
  const dispatch = useAppDispatch();

  const onClickCancel = () => {
    toggleModalOpen();
    window.dataLayer.push({
      event: 'cancel payment',
    });
  };

  const formikLike = useFormik<IStartTrial>({
    initialValues: {
      membership_type: '',
    },
    validationSchema: Yup.object({
      membership_type: Yup.string()
        .required('Required'),
    }),
    onSubmit: (values: IStartTrial) => {
      window.dataLayer.push({
        event: 'continue payment',
      });
      dispatch(checkoutPayment({
        membership_type: values.membership_type,
        location: profileInfo.current_location,
      }));
      toggleModalOpen();
    },
    enableReinitialize: true,
  });

  const {
    handleSubmit,
    values,
    errors,
    handleChange,
  } = formikLike;

  // @ts-ignore
  const prices = MEMBERSHIP_PRESET[profileInfo?.current_location] || MEMBERSHIP_PRESET.AU;

  return (
    <>
      <ModalWindow
        modalIsOpen={modalIsOpen}
        onCloseModal={toggleModalOpen}
        size="wide"
      >
        <ModalTitle
          title="Let the music play!"
        />
        <p className="modal__text">
          To keep the good times rolling, it&apos;s time to select your preferred membership.
          We&apos;re so sure you&apos;ll love it, we offer a 14 days money back guarantee!
        </p>
        <form
          onSubmit={handleSubmit}
        >
          <div className="row align-items-end">
            {
              prices?.map((plan: any) => (
                <div className="col-xs-12 col-md-6" key={plan.title}>
                  <RadioButtonCard
                    name="membership_type"
                    title={plan.title}
                    price={plan.price}
                    priceByMonth={plan.priceByMonth}
                    onChange={handleChange}
                    value={plan.value}
                    checked={values.membership_type === plan.value}
                    id={plan.value}
                    hideHead={plan.value === EMembershipType.HALF_YEAR}
                    location={profileInfo?.current_location}
                    description={plan.description}
                  />
                </div>
              ))
            }
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-auto">
              <Button
                type="submit"
                color="primary"
              >
                Continue
              </Button>
            </div>
            <div className="col-12 col-sm-6 col-md-auto">
              <Button
                type="button"
                color="secondary"
                onClick={onClickCancel}
              >
                Cancel
              </Button>
            </div>
            <div className="col-12 col-md-auto">
              Note: You will be directed to Stripe, where you will need to verify your credit card.
            </div>
          </div>
        </form>
        <ul className="payment-footer-menu">
          <li className="payment-footer-menu__item">
            <a
              href="https://www.verasounds.com/terms"
              target="_blank"
              rel="noopener noreferrer"
              className="payment-footer-menu__link"
            >
              Terms &#38; conditions
            </a>
          </li>
          <li className="payment-footer-menu__item">
            <a
              href="https://www.verasounds.com/policy"
              target="_blank"
              rel="noopener noreferrer"
              className="payment-footer-menu__link"
            >
              Cancellation policy
            </a>
          </li>
        </ul>
      </ModalWindow>
    </>
  );
};

export default StartTrialModal;

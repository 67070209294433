import axios from 'axios';
import config, {
  handleRequest,
  handleResponse,
  handleResponseError,
} from './initialConfig';

const AxiosClient = axios.create({
  ...config,
  baseURL: process.env.REACT_APP_API_URL,
});

const AxiosClientWithoutTimeout = axios.create({
  ...config,
  timeout: 0,
  baseURL: process.env.REACT_APP_API_URL,
});

export const setupResponseInterceptor = (store: any) => {
  AxiosClient.interceptors.request.use(handleRequest);
  AxiosClient.interceptors.response.use(
    (response) => handleResponse(response, store),
    (error) => handleResponseError(error, store),
  );
  AxiosClientWithoutTimeout.interceptors.request.use(handleRequest);
  AxiosClientWithoutTimeout.interceptors.response.use(
    (response) => handleResponse(response, store),
    (error) => handleResponseError(error, store),
  );
};

export default AxiosClient;
export {
  AxiosClientWithoutTimeout,
};

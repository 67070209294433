import React from 'react';
import Button, { EButtonType } from '../Button';
import OverlayPlayerBanner from './index';

interface IRelaxOverlayPlayerBanner {
  onStartListening(): void;
  onShufflePlay(): void;
}

const RelaxOverlayPlayerBanner: React.FunctionComponent<IRelaxOverlayPlayerBanner> = ({
  onStartListening,
  onShufflePlay,
}) => (
  <OverlayPlayerBanner
    title="Relax"
    text="Let's play some music to help your mind and body relax."
    image="/images/relax.png"
  >
    <div className="greeting-banner__buttons">
      <div className="row">
        <div className="col-auto">
          <Button
            type="button"
            color="primary"
            size="middle"
            view={EButtonType.RELAX}
            className="greeting-banner__button"
            onClick={onStartListening}
          >
            Start Listening
          </Button>
        </div>
        <div className="col-auto">
          <Button
            type="button"
            color="secondary"
            size="middle"
            view={EButtonType.RELAX}
            className="greeting-banner__button"
            onClick={onShufflePlay}
          >
            Shuffle Play
          </Button>
        </div>
      </div>
    </div>
  </OverlayPlayerBanner>
);

export default RelaxOverlayPlayerBanner;

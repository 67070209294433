enum ROUTES {
  HOME = '/',
  PLAYLIST = '/playlist',
  RELAX = '/playlist/relax',
  REMINISCE = '/playlist/reminisce',
  ENERGISE = '/playlist/energise',
  PROFILE = '/profile',
  PERSONAL_INFO = '/profile/personal-info',
  MUSIC_PREFERENCES = '/profile/music-preferences',
  MUSIC_PREFERENCES_SONGS = '/profile/music-preferences/songs',
  MUSIC_PREFERENCES_ARTISTS = '/profile/music-preferences/artists',
  MUSIC_PREFERENCES_GENRES = '/profile/music-preferences/genres',
  ACCOUNT = '/account',
  MEMBERSHIP = '/account/membership',
  SETTINGS = '/account/settings',
  SETTINGS_PASSWORD = '/account/settings/password',
  SETTINGS_GUEST_ADMIN = '/account/settings/guest-admin',
  SETTINGS_SHARE_PROFILE = '/account/settings/share-profile',
  SETTINGS_TEAM_MEMBERS = '/account/settings/team-members',
  LOGIN = '/login',
  LOGIN_PRO = '/pro/login',
  SIGNUP = '/signup',
  SIGNUP_PRO = '/pro/signup',
  LOGOUT = '/logout',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset',
  PROFILE_CREATION = '/profile-creation',
  PROFILE_CREATION_1 = '/profile-creation/step-1',
  PROFILE_CREATION_2 = '/profile-creation/step-2',
  PROFILE_CREATION_3 = '/profile-creation/step-3',
  PROFILE_CREATION_4 = '/profile-creation/step-4',
  PROFILE_CREATION_5 = '/profile-creation/step-5',
  PROFILE_CREATION_6 = '/profile-creation/step-6',
  PROFILE_CREATION_PRO_1 = '/vera-pro/profile-creation/step-1',
  PROFILE_CREATION_PRO_2 = '/vera-pro/profile-creation/step-2',
  PROFILE_CREATION_FACILITY_MEMBER_1 = '/vera-pro/facility-member/profile-creation/step-1',
  PROFILE_CREATION_FACILITY_MEMBER_2 = '/vera-pro/facility-member/profile-creation/step-2',
  PROFILE_CREATION_PRO_3 = '/vera-pro/profile-creation/step-3',
  PROFILE_CREATION_PRO_SUCCESS = '/vera-pro/profile-creation/success',
  PROFILE_CREATION_MEMBERSHIP = '/profile-creation/membership',
  SIGNUP_GUEST_ADMIN = '/account-admin/signup',
  PROFILE_CREATION_GUEST_ADMIN_1 = '/account-admin/step-1',
  PROFILE_CREATION_GUEST_ADMIN_2 = '/account-admin/step-2',
  NOTIFY_ME = '/notify-me',
  GOOD_NEWS = '/goodNews',
  THANK_YOU = '/thankYou',
  PAYMENT_SUCCESS = '/payment/success/',
  PAYMENT_CANCELLED = '/payment/cancelled/',
  CONTINUE_ONBOARDING = '/continue-onboarding/',
  VERIFY_EMAIL = '/verify-email',
  MANAGE = '/manage',
  PROFILES = '/manage/profiles',
  PROFILES_DETAIL = '/manage/profiles',
  PROFILES_DETAIL_MUSIC_PREFERENCES = '/manage/profiles/:id/music-preferences/',
  PROFILES_DETAIL_MUSIC_PREFERENCES_SONGS = '/manage/profiles/:id/music-preferences/songs',
  PROFILES_DETAIL_MUSIC_PREFERENCES_ARTISTS = '/manage/profiles/:id/music-preferences/artists',
  PROFILES_DETAIL_MUSIC_PREFERENCES_GENRES = '/manage/profiles/:id/music-preferences/genres',
  PROFILES_ADD_PROFILE = '/manage/profiles/add-profile',
  SCHEDULING = '/manage/scheduling',
  WELCOME_TO_VERA = '/welcome-to-vera',
}

export default ROUTES;

import React, {
  useCallback, useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import NavBar from '../components/NavBar';
import MusicPlayer from '../components/MusicPlayer';
import ROUTES from '../routes/constants';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import {
  REDUCER_KEY_PLAYER,
  REDUCER_KEY_PROFILE,
  REDUCER_KEY_STRIPE,
  REDUCER_KEY_TRACKS,
} from '../constants';
import RelaxOverlayPlayerBanner from '../components/OverlayPlayerBanner/RelaxOverlayPlayerBanner';
import ReminisceOverlayPlayerBanner from '../components/OverlayPlayerBanner/ReminisceOverlayPlayerBanner';
import EnergiseOverlayPlayerBanner from '../components/OverlayPlayerBanner/EnergiseOverlayPlayerBanner';
import {
  setPlay,
  setPlayingTrack,
  setPlaylist,
  setShufflePlay,
  setStartedListeningDate,
} from '../store/reducers/playerReducer';
import Preloader from '../components/Preloader';
import { sameDay } from '../helpers';

declare global {
  interface Window {
    FS: any;
    Intercom: any;
  }
}

const MusicLayout: React.FunctionComponent<Record<string, unknown>> = ({
  children,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [showBanner, setShowBanner] = useState(true);
  const [isToday, setIsToday] = useState(false);
  const {
    playlist,
    player,
    playerPlaylist,
    profile,
    isPlaying,
    isFetching,
    isFetchingPlaylist,
    isFetchingTracks,
    isFetchingStripeKey,
    startedListeningDate,
  } = useAppSelector((state) => ({
    playlist: state[REDUCER_KEY_PROFILE].playlist,
    player: state[REDUCER_KEY_PLAYER],
    playerPlaylist: state[REDUCER_KEY_PLAYER].playlist,
    profile: state[REDUCER_KEY_PROFILE].profiles[0],
    isPlaying: state[REDUCER_KEY_PLAYER].isPlaying,
    isFetching: state[REDUCER_KEY_PROFILE].isFetching,
    isFetchingPlaylist: state[REDUCER_KEY_PROFILE].isFetchingPlaylist,
    isFetchingTracks: state[REDUCER_KEY_TRACKS].isFetching,
    isFetchingStripeKey: state[REDUCER_KEY_STRIPE].isFetching,
    startedListeningDate: state[REDUCER_KEY_PLAYER].startedListeningDate,
  }));
  const isSmallPlayer = useMemo(() => {
    if (playerPlaylist) {
      return history.location.pathname !== `/playlist/${playerPlaylist?.name.toLowerCase()}`;
    }
    return history.location.pathname !== ROUTES.HOME
      && history.location.pathname !== ROUTES.RELAX
      && history.location.pathname !== ROUTES.REMINISCE
      && history.location.pathname !== ROUTES.ENERGISE;
  }, [history.location.pathname, playerPlaylist]);

  const handleStartListening = useCallback(() => {
    if (playlist) {
      dispatch(setPlay({ isPlaying: false }));
      dispatch(setShufflePlay({ isShuffled: false }));
      dispatch(setPlaylist(playlist));
      dispatch(setPlay({ isPlaying: true }));
      dispatch(setPlayingTrack({ trackId: playlist.tracks[0].id }));
      if (!startedListeningDate || !isToday) {
        dispatch(setStartedListeningDate({ startedListeningDate: new Date() }));
      }
    }
    // eslint-disable-next-line
  }, [playlist]);

  const handleShufflePlay = useCallback(() => {
    if (playlist) {
      dispatch(setShufflePlay({ isShuffled: true }));
      dispatch(setPlaylist(playlist));
      dispatch(setPlay({ isPlaying: true }));
      dispatch(setPlayingTrack({ trackId: playlist.tracks[0].id }));
      if (!startedListeningDate || !isToday) {
        dispatch(setStartedListeningDate({ startedListeningDate: new Date() }));
      }
    }
    // eslint-disable-next-line
  }, [playlist]);

  useEffect(() => {
    if (isPlaying) {
      setShowBanner(false);
    } else if (!playerPlaylist) {
      setShowBanner(true);
    }
  }, [isPlaying, playerPlaylist]);

  useEffect(() => {
    if (profile && window.FS) {
      window.FS.identify(profile.id, {
        displayName: profile.account_name,
        email: profile.email,
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsToday(sameDay(startedListeningDate));
  }, [startedListeningDate]);

  return (
    <div className="app">
      {
        isFetching
        && (<Preloader notTransparent />)
      }
      {
        (isFetchingPlaylist
          || isFetchingTracks
          || isFetchingStripeKey)
        && (<Preloader />)
      }
      <div className="layout">
        <aside className="layout__aside">
          <NavBar />
        </aside>
        <section className="layout__main">
          <div
            className={classNames('layout-main-container', {
              'layout-main-container--small-player': isSmallPlayer,
              'layout-main-container--without-small-player': isSmallPlayer && player?.playingTrackId === undefined,
            })}
          >
            <div className="music-player-wrapper">
              {
                isSmallPlayer
                  && player?.playingTrackId === undefined
                  ? (
                    <></>
                  ) : (
                    <MusicPlayer
                      small={isSmallPlayer}
                    />
                  )
              }
              {
                showBanner
                && history.location.pathname === ROUTES.RELAX
                && !playerPlaylist
                && (
                  <RelaxOverlayPlayerBanner
                    onStartListening={handleStartListening}
                    onShufflePlay={handleShufflePlay}
                  />
                )
              }
              {
                showBanner
                && history.location.pathname === ROUTES.REMINISCE
                && !playerPlaylist
                && (
                  <ReminisceOverlayPlayerBanner
                    onStartListening={handleStartListening}
                    onShufflePlay={handleShufflePlay}
                  />
                )
              }
              {
                showBanner
                && history.location.pathname === ROUTES.ENERGISE
                && !playerPlaylist
                && (
                  <EnergiseOverlayPlayerBanner
                    onStartListening={handleStartListening}
                    onShufflePlay={handleShufflePlay}
                  />
                )
              }
            </div>
            <div className="scroll-container">
              {children}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default MusicLayout;
